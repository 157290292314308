import axios from 'axios';
import Cookies from "js-cookie";
import { decodeAuth } from '../utils/Helper';

export const publicGetMethodService = async (api) => {
    try {
        const response = await axios({
            method: 'get',
            url: api,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }
        });
        if(response?.status === 500){
            return response?.message || response?.errors || "Something Wrong!";
        };
        if(response.status === 429){
            return response?.message || response?.errors || "Something Wrong!";
        };
        if(response?.status === 400){
            return response?.message
        };
        if(response?.status === 401 || response.status === 403){
            return "Unauthenticated";
        };
        if(response.status === 404){
            return 404;
        };
        if(response?.status === 200){
            return response?.data;
        };
    } 
    catch (error) {
        if(error?.message === "Failed to fetch"){
            console.log(error);
            // errorToaster("ဆာဗာနှင့်ချိတ်ဆက်၍မရပါ ပြန်လည်ကြိုးစားပါ");
        }
    }
}

export const getMethodService = async (api) => {
    const getAuth = Cookies.get('=d;£6792)Dr!');
    let userAuth = getAuth && decodeAuth(getAuth);
    try {
        const response = await axios({
            method: 'get',
            url: api,
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                "Authorization": `${userAuth?.token}`,
            }
        });
        if(response?.status === 500){
            return response?.message || response?.errors || "Something Wrong!";
        };
        if(response.status === 429){
            return response?.message || response?.errors || "Something Wrong!";
        };
        if(response?.status === 400){
            return response?.message
        };
        if(response?.status === 401 || response.status === 403){
            return "Unauthenticated";
        };
        if(response.status === 404){
            return 404;
        };
        if(response?.status === 200){
            return response?.data;
        };
    } 
    catch (error) {
        if(error?.message === "Failed to fetch"){
            console.log(error);
            // errorToaster("ဆာဗာနှင့်ချိတ်ဆက်၍မရပါ ပြန်လည်ကြိုးစားပါ");
        }
    }
}

export const postMethodService = async (api,data) => {
    const getAuth = Cookies.get('=d;£6792)Dr!');
    let userAuth = getAuth && decodeAuth(getAuth);
    try{
        let fetcher = await fetch(api ,{
            method: "POST",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `${userAuth?.token}`,
            },
            body: JSON.stringify(data),
        });
        let res = await fetcher.json();
        if(fetcher.status === 500){
            return res?.message || res?.errors || "Something Wrong!";
        };
        if(fetcher.status === 429){
            return res?.message || res?.errors || "Something Wrong!";
        };
        if(fetcher.status === 400){
            
        };
        if(fetcher.status === 401 || fetcher.status === 403){
            return "Unauthenticated";
        };
        if(fetcher.status === 404){
            return 404;
        };
        if(fetcher.status === 200){
            if(res){
                if(res.status === "success" || res.status || res.success){
                    return res;
                }
            };
        };
    }
    catch(err){
        console.log(err);
        if(err.message === "Failed to fetch"){
            return "Please check internet connection";
        };
    }
};

export const putMethodService = async (api,data) => {
    const getAuth = Cookies.get('=d;£6792)Dr!');
    let userAuth = getAuth && decodeAuth(getAuth);
    try{
        let fetcher = await fetch(api ,{
            method: "PUT",
            headers: {
                "Accept": "application/json",
                "Content-Type": "application/json",
                "Authorization": `${userAuth?.token}`,
            },
            body: JSON.stringify(data),
        });
        let res = await fetcher.json();
        if(fetcher.status === 500){
            return res?.message || res?.errors || "Something Wrong!";
        };
        if(fetcher.status === 429){
            return res?.message || res?.errors || "Something Wrong!";
        };
        if(fetcher.status === 400){
            
        };
        if(fetcher.status === 401 || fetcher.status === 403){
            return "Unauthenticated";
        };
        if(fetcher.status === 404){
            return 404;
        };
        if(fetcher.status === 200){
            if(res){
                if(res.status === "success" || res.status || res.success){
                    return res;
                }
            };
        };
    }
    catch(err){
        console.log(err);
        if(err.message === "Failed to fetch"){
            return "Please check internet connection";
        };
    }
};