import React from 'react';
import classes from './SmallLoader.module.css'

const SmallLoader = ({width}:{width: string}) => {
  return (
    <div style={{width}} className={classes.loader}></div>
  )
}

export default SmallLoader
