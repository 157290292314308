import React from 'react'
import { createPortal } from 'react-dom';
import { useNavigate, useParams } from 'react-router-dom'
import Loader from '../../utils/Loader/Loader';
import classes from './WalletTransactionDetail.module.css';
import { useSelector } from 'react-redux';
import { langChange } from '../../lang';
import { BiSolidMinusCircle } from 'react-icons/bi';
import { getTransactionHistoryDetail } from '../../services/userService';
import { capitalizedText } from '../../utils/Helper';
import moment from 'moment';
import { FaRegClock } from 'react-icons/fa';
const depositIcon = require("../../assets/images/icons/deposit.png");
const withdrawlIcon = require("../../assets/images/icons/withdrawal.png");
const betIcon = require("../../assets/images/icons/bet.png");
const rewardIcon = require("../../assets/images/icons/reward.png");
const rejectedIcon = require("../../assets/images/icons/rejected.png");

const WalletTransactionDetail = () => {
  const {id} = useParams();
  const {value} = useSelector((state:any) => state.lang);
  const navigate = useNavigate();
  
  const {
    loading: walletTransactionLoading,
    error: walletTransactionError,
    data: walletTransactionData,
    refetch: walletTransactionRefetch,
    startPolling: walletTransactionStartPolling,
  }:any = getTransactionHistoryDetail({
    payload: {
      walletTransactionId: id
    },
    pollInterval: 0,
    isLazy: false,
  });

  let _showTransactionType = (type: String, lang: String) => {
    switch (type) {
      case "betting-reject":
        return lang === "eng" ? "Rejected" : "ပွဲပျက်";
      case "deposit":
        return lang === "eng" ? "Deposit" : "ငွေသွင်း";
      case "withdrawl":
        return lang === "eng" ? "Withdrawal" : "ငွေထုတ်";
      case "betting":
        return lang === "eng" ? "Bet" : "လောင်းငွေ";
      case "reward":
        return lang === "eng" ? "Reward" : "နိုင်ငွေ";
      default:
        return lang === "eng" ? "All" : "အားလုံး";
    }
  };

  let _showImage = (type: String) => {
    switch (type) {
      case "betting-reject":
        return rejectedIcon;
      case "deposit":
        return depositIcon;
      case "withdrawl":
        return withdrawlIcon;
      case "betting":
        return betIcon;
      case "reward":
        return rewardIcon;
    }
  };

  const _showSlipId = (betId:any,type:any) => {
    let mustTypes = ['betting', 'reward', 'reject', 'rollback'];
    let findType = mustTypes.some(t => t === type);
    if(findType){
        if(betId){
          return <span 
                    style={{
                      color: '#007aff',
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={(e)=> {e.stopPropagation();navigate(`/bet-slips/${betId}`)}}>
                    {betId}
                 </span>;
        }else{
          return '-'
        };
    };
  };


  return (
    <>
      <div
        className={classes.passenger}
        style={{ cursor: "pointer" }}
      >
        <div className={classes["passenger-ticket"]}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              fontSize: "13px",
            }}
          >
            <FaRegClock size={20} style={{ marginRight: "7px" }} />
            <div style={{ width: "100%" }}>
              {/* <p style={{marginBottom: '5px', color: 'var(--accent-color)'}}>Africa - World Cup Qualifying</p> */}
              <p
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <span>
                    {
                      moment(walletTransactionData?.walletTransaction?.created_at).format("DD-MM-YYYY")
                    }
                </span>
                <span>
                    {
                      moment(walletTransactionData?.walletTransaction?.created_at).format("hh:mm:ss A")
                    }
                </span>
              </p>
            </div>
          </div>
          <div className={classes["passenger-ticket__bottom"]}>
            <div
              className={`${classes.circle} ${classes["circle-left"]}`}
            />
            <div
              className={`${classes.circle} ${classes["circle-right"]}`}
            />
          </div>
        </div>
        <div className={classes["passenger-ticket"]}>
          <div className={classes.paymentIconContainer}>
               <img className={classes.typeIconImg} src={_showImage(walletTransactionData?.walletTransaction?.transaction_type)}/>
               <p className={classes.payment_success}>{langChange.payment_success}</p>

               {walletTransactionData?.walletTransaction?.type == "increase" && (
                  <div className={classes.payment_amount} style={{ color: "#198754" }}>
                    +{walletTransactionData?.walletTransaction?.amount?.toLocaleString("en-US")}
                  </div>
                )}
                {walletTransactionData?.walletTransaction?.type == "decrease" && (
                  <div className={classes.payment_amount} style={{ color: "#dc3545" }}>
                    -{walletTransactionData?.walletTransaction?.amount?.toLocaleString("en-US")}
                  </div>
                )}
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: value === "eng"? "8px": 0,
            }}
          >
            <div style={{ width: "50%" }}>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "13px",
                  color: "#ffffff75",
                  marginBottom: "0",
                }}
              >
                {langChange.transaction_id}
              </p>
            </div>
            <div style={{ width: "50%" }}>
              <p
                style={{
                  fontSize: "13px",
                  color: "#ffffffad",
                  marginBottom: "0",
                  wordBreak: "break-all",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  fontWeight: "bolder",
                }}
              >
                {walletTransactionData?.walletTransaction?.id}
              </p>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: value === "eng"? "8px": 0,
            }}
          >
            <div style={{ width: "50%" }}>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "13px",
                  color: "#ffffff75",
                  marginBottom: "0",
                }}
              >
                {langChange.slipId}
              </p>
            </div>
            <div style={{ width: "50%" }}>
              <p
                style={{
                  fontSize: "13px",
                  color: "#ffffffad",
                  marginBottom: "0",
                  wordBreak: "break-all",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                  fontWeight: "bolder",
                }}
              >
                {
                  _showSlipId(walletTransactionData?.walletTransaction?.remark,walletTransactionData?.walletTransaction?.transaction_type)
                }
              </p>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: value === "eng"? "8px": 0,
            }}
          >
            <div style={{ width: "50%" }}>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "13px",
                  color: "#ffffff75",
                  marginBottom: "0",
                }}
              >
                {langChange.before}
              </p>
            </div>
            <div style={{ width: "50%" }}>
              <p
                style={{
                  fontWeight: "bolder",
                  fontSize: "13px",
                  color: '#ffffffad',
                  marginBottom: "0",
                  wordBreak: "break-all",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {walletTransactionData?.walletTransaction?.before?.toLocaleString("en-US")}
              </p>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: value === "eng"? "8px": 0,
            }}
          >
            <div style={{ width: "50%" }}>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "13px",
                  color: "#ffffff75",
                  marginBottom: "0",
                }}
              >
                {langChange.amount}
              </p>
            </div>
            <div style={{ width: "50%" }}>
              <p
                style={{
                  fontWeight: "bolder",
                  fontSize: "13px",
                  color: '#ffffffad',
                  marginBottom: "0",
                  wordBreak: "break-all",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {walletTransactionData?.walletTransaction?.type == "increase" && (
                  <div style={{ color: "#198754" }}>
                    +{walletTransactionData?.walletTransaction?.amount?.toLocaleString("en-US")}
                  </div>
                )}
                {walletTransactionData?.walletTransaction?.type == "decrease" && (
                  <div style={{ color: "#dc3545" }}>
                    -{walletTransactionData?.walletTransaction?.amount?.toLocaleString("en-US")}
                  </div>
                )}
              </p>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              marginBottom: value === "eng"? "8px": 0,
            }}
          >
            <div style={{ width: "50%" }}>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "13px",
                  color: "#ffffff75",
                  marginBottom: "0",
                }}
              >
                {langChange.after}
              </p>
            </div>
            <div style={{ width: "50%" }}>
              <p
                style={{
                  fontWeight: "bolder",
                  fontSize: "13px",
                  color: '#ffffffad',
                  marginBottom: "0",
                  wordBreak: "break-all",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-end",
                }}
              >
                {walletTransactionData?.walletTransaction?.after?.toLocaleString("en-US")}
              </p>
            </div>
          </div>
          <div className={classes["passenger-ticket__bottom"]}>
            <div
              className={`${classes.circle} ${classes["circle-left"]}`}
            />
            <div
              className={`${classes.circle} ${classes["circle-right"]}`}
            />
          </div>
        </div>
        <div className={classes["passenger-ticket"]}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <div style={{ width: "50%" }}>
              <p
                style={{
                  textAlign: "left",
                  fontSize: "13px",
                  color: "#ffffff75",
                  marginBottom: "0",
                }}
              >
                {langChange.bet_type}
              </p>
            </div>
            <div style={{ width: "50%" }}>
                <p
                  style={{
                    fontWeight: "bolder",
                    fontSize: "13px",
                    color: "var(--accent-color)",
                    marginBottom: "0",
                    wordBreak: "break-all",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                  }}
                >
                  {
                    _showTransactionType(walletTransactionData?.walletTransaction?.transaction_type, value)
                  }
                </p>
            </div>
          </div>
          <div className={classes["passenger-ticket__bottom"]}>
            <div
              className={`${classes.circle} ${classes["circle-left"]}`}
            />
            <div
              className={`${classes.circle} ${classes["circle-right"]}`}
            />
          </div>
        </div>
      </div>
      {createPortal(
        walletTransactionLoading && <Loader />,
        document.getElementById("loading") as HTMLElement
      )}
    </>
  )
}

export default WalletTransactionDetail
