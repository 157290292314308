import React from 'react'
import Layout from '../../layout/Layout'
import Body from '../../components/Football/Body/BodyTest'
import { langChange } from '../../lang'

const BodyTestPage = () => {
  return (
    <Layout type='football' title={langChange.body} showHeader={false} filter="full-time" showFooter={false} home={false} showDate={false} download={false} showWallet={false} search={false}>
       <Body />
    </Layout>
  )
}

export default BodyTestPage
