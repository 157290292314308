import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import classes from './ButSubmit.module.css'
import { calculatePotentialWin, decodeAuth, encodeAuth, LogoutHandle, replaceZeotoQqual, touchSong } from '../../../utils/Helper';
import { AutoComplete, Button, Form, Tag, message,Input,Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { setBetLists } from '../../../store/slices/betListsSlice';
import { postMethodService } from '../../../services';
import { FOOTBALL_BET_API, TEAM_IMAGE } from '../../../services/apis';
import { createPortal } from 'react-dom';
import Loader from '../../../utils/Loader/Loader';
import { FiX } from "react-icons/fi";
import { GrEdit } from "react-icons/gr";
import { MdStar } from 'react-icons/md';
import {io} from "socket.io-client";
import { FaCheck } from 'react-icons/fa';
import { refetchHandler } from '../../../store/slices/refreshBalanceSlice';
import { langChange } from '../../../lang';
import moment from 'moment';
// import { callStatusHandler } from '../../../store/slices/callMatchsApiSlice';
const noImage = require('../../../assets/images/icons/no-image.png');
const { TextArea } = Input;
const { Paragraph } = Typography;
const options = [
    { value: 'I hope I win.' },
    { value: 'Wish me luck!' },
    { value: 'May I be lucky.' },
    { value: 'I wish for a win.' },
    { value: 'Hoping for a big win.' },
    { value: 'Let me have good luck.' },
    { value: 'May my bet-slip win' },
    { value: 'I’m feeling lucky today.' },
    { value: 'Let this be my lucky day.' },
    { value: 'Good luck with the betting lines!' },
    { value: 'I’m betting on your success!' },
    { value: 'Go for the win!' },
    { value: 'Bet to win!' },
    { value: 'Win big today!' },
    { value: 'Get that win!' },
    { value: 'Make it a win!' },
    { value: 'Let’s win this!' },
];

const BetSubmit = () => {
  const [isLoading,setIsLoading] = useState(false);
  const [betData,setBetData] = useState<any>({});
  const [newOddsFt,setNewOddsFt] = useState<any>({});
  const [newOddsFh,setNewOddsFh] = useState<any>({});
  const [twoOdds,setTwoOdds] = useState("");
  const {betLists} = useSelector((state:any) => state.betLists);
  const [betSlips,setBetSlips] = useState([]);
  const [remark,setRemark] = useState("");
  const navigate = useNavigate();
  const [messageApi, contextHolder] = message.useMessage();
  const [isEdit,setIsEdit] = useState(false);
  const refetchStatus = useSelector((state:any) => state.refreshBalance.refreshStatus);
  const {value} = useSelector((state:any) => state.lang);
  const dispatch = useDispatch();

//   const {taxObj:tax_percent_data} = useSelector((state:any) => state.tax);
  const getTax = localStorage.getItem('tax');
  useEffect(()=>{
    if(!getTax){
        return navigate('/');
    };
  },[]);
  const tax_percent_data = decodeAuth(getTax);
  let potential_winning = calculatePotentialWin(betLists,tax_percent_data)

  const alertMessage = (status:any, msg:any) => {
    messageApi.open({
      key: 'betMessage',
      type: status,
      content: msg,
    });
  };


  useEffect(()=>{
    let url:any= process.env.REACT_APP_SOCCER_DOMAIN;
    const socket = io(url,{
        transports: ["websocket"],
        path: '/api/soccer/socket',
    });
    socket.connect();
    socket.on('connect', () => {
        console.log("Soc Suc!");
        // setOddsStatus(true);
    });
    socket.on('disconnect', () =>  {
        console.log("Soc Dec!");
        // setOddsStatus(false);
    });
    socket.on("ft_odds_updated_data", res => {
      // console.log("ft_odds_updated_data received", res);
      setNewOddsFt(res);
    });
    socket.on("fh_odds_updated_data", res => {
      // console.log("fh_odds_updated_data received", res);
      setNewOddsFh(res);
    });
    socket.on("hello", res => {
      console.log("hello received", res);
        // setNewOdds(res);
    });
    return () => {
        socket.disconnect();
    };
  },[]);

  useEffect(() => {
    if (betSlips.length > 0) {
        const updatedBetSlips:any = betSlips.map((match:any) => {
            if (match?.match_id === newOddsFt?.match_id) {
                match = {
                    ...match,
                    fixture: {
                        ...match.fixture,
                        odds: {
                            ...match.fixture.odds,
                            full_time: {
                                ...match.fixture.odds.full_time,
                                hdp_mm_odds: newOddsFt?.data?.hdp_mm_odds || match?.fixture?.odds?.full_time?.hdp_mm_odds,
                                ou_mm_odds: newOddsFt?.data?.ou_mm_odds || match?.fixture?.odds?.full_time?.ou_mm_odds,
                                odd: newOddsFt?.data?.odd || match?.fixture?.odds?.full_time?.odd,
                                even: newOddsFt?.data?.even || match?.fixture?.odds?.full_time?.even,
                                one: newOddsFt?.data?.one || match?.fixture?.odds?.full_time?.one,
                                x: newOddsFt?.data?.x || match?.fixture?.odds?.full_time?.x,
                                two: newOddsFt?.data?.two || match?.fixture?.odds?.full_time?.two,
                            }
                        }
                    }
                };
            };
            
            if (match?.match_id === newOddsFh?.match_id) {
                match = {
                    ...match,
                    fixture: {
                        ...match.fixture,
                        odds: {
                            ...match.fixture.odds,
                            first_half: {
                                ...match.fixture.odds.first_half,
                                hdp_mm_odds: newOddsFh?.data?.hdp_mm_odds || match?.fixture?.odds?.first_half?.hdp_mm_odds,
                                ou_mm_odds: newOddsFh?.data?.ou_mm_odds || match?.fixture?.odds?.first_half?.ou_mm_odds,
                            }
                        }
                    }
                };
            }
            return match;
        });
        setBetSlips(updatedBetSlips);
    };
}, [newOddsFt, newOddsFh]);

  useEffect(()=>{
    if(!betLists){
        return navigate(-1);
    };
    if(betLists?.matches?.length === 0){
        alertMessage('error', 'ပွဲချိန်ကျော် နေပါသည်။');
        setTimeout(()=>{
            return navigate(-1);
        },1000)
    };
    
    setBetSlips(betLists?.matches);
    let tempCurrentMatch:any = [];
    let tempDelMatches:any = [];
    let data = {
        amount: Number(betLists?.amount),
        matches: betLists?.matches?.map((list:any) => {
            const isBeforeNow = moment(list?.fixture?.fixture_start_time).isAfter(moment());
            // true -> fixture_start_time ဟာ current time ထက် များ
            if(isBeforeNow){
                tempCurrentMatch.push(list);
                return {
                    match_id: Number(list?.match_id),
                    market: list?.market,
                    team: list?.team,
                    match_stage: list?.match_stage
                };
            }else{
                tempDelMatches.push(list);
            };
        }),
        remark
    };
    if(tempDelMatches.length > 0){
        localStorage.setItem("bets",encodeAuth(tempCurrentMatch));
        dispatch(setBetLists({matches: tempCurrentMatch,amount: Number(betLists?.amount)}));
    };
    setBetData(data);
  },[betLists,remark]);

  const backHandler = () => {
    touchSong();
    dispatch(setBetLists(null));
  };

const betHandler = async () => {
    touchSong();
    if (!betData?.amount) {
        return alertMessage('error', 'လောင်းကြေးထည့်ပါ');
    };
    // if (Number(betData?.amount) < 500) {
    //     return alertMessage('error', 'အနည်းဆုံး 500 ထည့်ပါ။');
    // }
    setIsLoading(true);
    let res:any = await postMethodService(FOOTBALL_BET_API, betData);

    if (!res) {
        setIsLoading(false);
        return;
    }

    if (res === "Unauthenticated") {
        return LogoutHandle(navigate);
    }
    
    if (res.status === "success" || res.status || res.success) {
        alertMessage('success', 'အောင်မြင်ပါသည်။');
        dispatch(refetchHandler(!refetchStatus));
        // dispatch(callStatusHandler(false));
        setTimeout(() => {
            localStorage.removeItem("actives");
            localStorage.removeItem("bets");
            dispatch(setBetLists(null));
        }, 800);
    } else {
        setIsLoading(false);
        alertMessage('error', res);
    }
};


  const deleteMatchHandler = (id:any) => {
    let matches =  betLists?.matches;
    if(matches?.length > 2){
        matches = matches.filter((match:any) => match?.match_id != id);
        let obj = {
            ...betLists,
            matches
        }
        dispatch(setBetLists(obj));
    }else{
        return alertMessage('error','အနည်းဆုံး ၂ မောင်းလောင်းပါ။');
    };
  };

  const editAmountHandler = (e:any) => {
        let amount = e.target.value;
        if(!isNaN(Number(amount))){
            let obj = {
                ...betLists,
                amount
            };
            dispatch(setBetLists(obj));
        };
  };

  return (
    <>
      {contextHolder}
      <div style={{
         position: 'relative',
         height: '100%',
         paddingBottom: '225px',
         overflow: 'hidden'
      }}>
        <div style={{
            height: '100%',
            overflow: 'hidden scroll',
        }}>
        {
            (betLists && betSlips?.length > 0 ) &&
            betSlips?.map((betList:any) => 
                <div className={classes.card} key={betList?.match_id}
                >
                    <time className={classes.start_time}>
                        Start Time : {`${moment(betList?.fixture?.fixture?.fixture_start_time).format('DD/MM')} - 
                        ${moment(betList?.fixture?.fixture?.fixture_start_time).format('hh:mm A')}`}
                    </time>   
                    {
                        betLists?.matches?.length > 2 &&
                        <button className={classes.deleteBtn} onClick={() => deleteMatchHandler(betList?.match_id)}> 
                            <FiX color='#FFF' size={15} />
                        </button>
                    }
                    <div className={classes.team_container}>
                        <div className={classes.teamH}>
                            {
                                (value === "unicode" || value === "zawgyi") ? (betList?.fixture?.host_team_data?.name_mm || betList?.fixture?.host_team_data?.name_en) : betList?.fixture?.host_team_data?.name_en
                            }
                            <span className={classes.teamLogoBg}>
                                <img src={TEAM_IMAGE(betList?.fixture?.host_team_data)} />
                            </span>
                        </div>
                        <span style={{backgroundColor: '#000626' , height: '40px', lineHeight: '40px',padding: '0 3px',fontSize: '13px', color: 'var(--accent-color)'}}>
                            Vs
                        </span>
                        <div className={classes.teamA}>
                            <span className={classes.teamLogoBg}>
                                <img src={TEAM_IMAGE(betList?.fixture?.guest_team_data)} />
                            </span>
                            {
                                (value === "unicode" || value === "zawgyi") ? (betList?.fixture?.guest_team_data?.name_mm || betList?.fixture?.guest_team_data?.name_en) : betList?.fixture?.guest_team_data?.name_en
                            }
                        </div>
                    </div> 
                    <div className={classes.betFlex}>
                        <div className={classes.type}>
                            {betList?.match_stage === "full_time" && (betList?.market === "body" || betList?.market === "total") ? 'FT': ''}
                            {betList?.match_stage === "first_half" ? 'HT': ''}
                            {betList?.market === "odd_even" ? 'E/O':''}
                            {betList?.market === "one_x_two" ? '1X2':''}
                            {betList?.market === "correct_score" ? 'CS':''}
                        </div>
                        <div style={{width: '100%'}}>
                            {
                                betList?.market === "body" &&
                                <div className={classes.hdp_container}>
                                    <div className={classes.home} style={{backgroundColor: betList?.team === 'home' ? 'var(--accent-color)':''}}>
                                        {langChange.football_home}
                                        {
                                            betList?.match_stage === "full_time" &&
                                            betList?.fixture?.odds?.full_time?.odds_team === 'home'?
                                            <span className={classes.home_odds} style={{backgroundColor: betList?.team === 'home' ? '#000626': '', color: betList?.team === 'home' ? '#FFF':''}}>
                                                {replaceZeotoQqual(betList?.fixture?.odds?.full_time?.hdp_mm_odds)}
                                            </span>:
                                            ''
                                        }
                                        {
                                            betList?.match_stage === "first_half" &&
                                            betList?.fixture?.odds?.first_half?.odds_team === 'home'?
                                            <span className={classes.home_odds} style={{backgroundColor: betList?.team === 'home' ? '#000626': '', color: betList?.team === 'home' ? '#FFF':''}}>
                                                {replaceZeotoQqual(betList?.fixture?.odds?.first_half?.hdp_mm_odds)}
                                            </span>:
                                            ''
                                        }
                                    </div>
                                    <div className={classes.away} style={{backgroundColor: betList?.team === 'away' ? 'var(--accent-color)':''}}>
                                        {langChange.football_away}
                                        {
                                            betList?.match_stage === "full_time" &&
                                            betList?.fixture?.odds?.full_time?.odds_team === 'away'?
                                            <span className={classes.away_odds} style={{backgroundColor: betList?.team === 'away' ? '#000626': '', color: betList?.team === 'away' ? '#FFF':''}}>
                                                {replaceZeotoQqual(betList?.fixture?.odds?.full_time?.hdp_mm_odds)}
                                            </span>:
                                            ''
                                        }
                                        {
                                            betList?.match_stage === "first_half" &&
                                            betList?.fixture?.odds?.first_half?.odds_team === 'away'?
                                            <span className={classes.away_odds} style={{backgroundColor: betList?.team === 'away' ? '#000626': '', color: betList?.team === 'away' ? '#FFF':''}}>
                                                {replaceZeotoQqual(betList?.fixture?.odds?.first_half?.hdp_mm_odds)}
                                            </span>:
                                            ''
                                        }
                                    </div>
                                </div>
                            }
                            {
                                betList?.market === "total" &&
                                <div className={classes.ou_container}>
                                    <div className={classes.over} style={{backgroundColor: betList?.team === 'over' ? 'var(--accent-color)':''}}>{langChange.football_over}</div>
                                    <div className={classes.ou_odds}>
                                        {
                                            betList?.match_stage === "full_time" &&
                                            <div style={{backgroundColor: '#000626',color: '#FFF'}}>{replaceZeotoQqual(betList?.fixture?.odds?.full_time?.ou_mm_odds)}</div>
                                        }
                                        {
                                            betList?.match_stage === "first_half" &&
                                            <div style={{backgroundColor: '#000626',color: '#FFF'}}>{replaceZeotoQqual(betList?.fixture?.odds?.first_half?.ou_mm_odds)}</div>
                                        }
                                    </div>
                                    <div className={classes.under} style={{backgroundColor: betList?.team === 'under' ? 'var(--accent-color)':''}}>{langChange.football_under}</div>
                                </div>
                            }
                            {
                                betList?.market === "odd_even" &&
                                <div className={classes.hdp_container}>
                                    <div className={classes.home} style={{borderRadius: '3px 0 0 3px',backgroundColor: betList?.team === 'even'? 'var(--accent-color)': ''}}>
                                        {langChange.football_even}
                                        <span className={classes.home_odds} style={{backgroundColor: betList?.team === 'even' ? '#000626': '', color: betList?.team === 'even'? '#FFF': ''}}>
                                            {betList?.fixture?.odds?.full_time?.even}
                                        </span>
                                    </div>
                                    <div className={classes.away} style={{borderRadius: '0 3px 3px 0',backgroundColor: betList?.team === 'odd'? 'var(--accent-color)': ''}}>
                                        {langChange.football_odd}
                                        <span className={classes.away_odds} style={{backgroundColor: betList?.team === 'odd'? '#000626': '', color: betList?.team === 'odd'? '#FFF': ''}}>
                                            {betList?.fixture?.odds?.full_time?.odd}
                                        </span>
                                    </div>
                                </div>
                            }
                            {
                                betList?.market === "one_x_two" &&
                                <div className={classes.hdp_container}>
                                    <div className={classes.home} style={{borderRadius: '3px 0 0 3px',backgroundColor: betList?.team === 'home'? 'var(--accent-color)': ''}}>
                                        1
                                        <span className={classes.home_odds} style={{backgroundColor: betList?.team === 'home'? '#000626': '', color: betList?.team === 'home'? '#FFF': ''}}>
                                            {betList?.fixture?.odds?.full_time?.one}
                                        </span>
                                    </div>
                                    <div className={classes.home} style={{borderRadius: '0',backgroundColor: betList?.team === 'draw'? 'var(--accent-color)': ''}}>
                                        X
                                        <span className={classes.home_odds} style={{backgroundColor: betList?.team === 'draw'? '#000626': '', color: betList?.team === 'draw'? '#FFF': ''}}>
                                            {betList?.fixture?.odds?.full_time?.x}
                                        </span>
                                    </div>
                                    <div className={classes.away} style={{borderRadius: '0 3px 3px 0', backgroundColor: betList?.team === 'away'? 'var(--accent-color)': ''}}>
                                        2
                                        <span className={classes.away_odds} style={{backgroundColor: betList?.team === 'away'? '#000626': '', color: betList?.team === 'away'? '#FFF': ''}}>
                                            {betList?.fixture?.odds?.full_time?.two}
                                        </span>
                                    </div>
                                </div>
                            }
                            {
                                betList?.market === "correct_score" &&
                                <div className={classes.hdp_container}>
                                    <div className={classes.over} style={{borderRadius: '3px', backgroundColor: 'var(--accent-color)'}}>
                                            {`${betList?.cs_odds} ( ${betList?.team} )`}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div>
            )
        }
        </div>
        <div style={{
            position: 'fixed',
            width: '95%',
            maxWidth: '460px',
            left: '0',
            right: '0',
            margin: '0 auto',
            bottom: '10px',
        }}>
            <div className={classes.card} style={{marginBottom: '0'}}>
                <div style={{ marginBottom: '10px'}}>
                    <div style={{padding: value === 'eng'?'6px': '3px'}} className={classes.showDesc}>
                        <div style={{paddingLeft: '40px', width: '100%'}}>{langChange.bet_amount}</div>
                        <div style={{width: '100%'}}>
                            <div className={classes.editContainer}>
                                {
                                    isEdit? <Input value={betLists?.amount} onChange={editAmountHandler} />:
                                    <div style={{display: 'flex', alignItems: 'center'}}>
                                        <span style={{marginRight: 10, fontSize: '12px', color: '#FFF'}}>{betLists?.amount}</span>
                                        {
                                            !isLoading &&
                                            <GrEdit size={15} color='var(--accent-color)' onClick={() => setIsEdit(true)}/>
                                        }
                                    </div>
                                }
                                <span className={classes.editIcon}>
                                    {
                                        (isEdit && !isLoading) &&
                                        <FaCheck size={15} color='green' onClick={() => setIsEdit(false)}/>
                                    }
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className={classes.showDesc}  style={{backgroundColor: '#0006262e', padding: value === 'eng'?'6px': '3px'}}>
                        <div style={{paddingLeft: '40px',width: '100%'}}>{langChange.estimate}</div>
                        <div style={{width: '100%'}}>{potential_winning}</div>
                    </div>
                </div>
                <div className='someRemarkContainer' style={{marginBottom: '10px'}}>
                    <AutoComplete
                        style={{
                            width: '100%',
                            fontSize: '12px',
                            borderRadius: '0',
                        }}
                        size='large'
                        options={options}
                        onChange={(value) => setRemark(value)}
                    >
                        <TextArea
                            placeholder={langChange.canwrite}
                            className="custom"
                            style={{ 
                                height: 60 ,
                                resize: 'none',
                                backgroundColor: '#0006262e',
                                color: '#FFF',
                                borderColor: '#ffffff33'
                            }}
                            value={remark}
                            onChange={(e:any) => setRemark(e.target.value)}
                        />
                    </AutoComplete>
                </div>
                <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '20px 0 10px 0'}}>
                    <Button onClick={backHandler} type="primary" style={{marginRight: '5px', backgroundColor: 'gray', width: '150px', height: '37px', boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"}} shape="round">
                        {langChange.cancel}
                    </Button>
                    <Button loading={isLoading} onClick={betHandler} type="primary" style={{marginLeft: '5px', backgroundColor: 'var(--accent-color)', width: '150px', height: '37px', boxShadow: "rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px"}} shape="round">
                        {langChange.confirm}
                    </Button>
                </div>
            </div>
        </div>
      </div>
    </>
  )
}

export default BetSubmit
