import React, { useEffect, useState } from 'react'
import { GET_FIXTURES_DATA_SERVICE } from '../../../services/graphql/queryService';
import { GET_FIXTURES_DATA } from '../../../services/graphql/queries';
import { LogoutHandle } from '../../../utils/Helper';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { createPortal } from 'react-dom';
import Loader from '../../../utils/Loader/Loader';
import { gql, useQuery } from '@apollo/client';

const Body = () => {
  const query = gql`
  query MatchQuery(
    $source: String
    $status: String
    $isPublished: Boolean
    $pageSize: Int
    $page: Int
    $isPerlay: Boolean
    $markets: [String]
    $matchStages: [String]
    $date: String
    $sorting: String
  ) {
    matches(
      source: $source
      status: $status
      is_published: $isPublished
      pageSize: $pageSize
      page: $page
      is_perlay: $isPerlay
      markets: $markets
      match_stages: $matchStages
      date: $date
      sorting: $sorting
    ) {
      id
      fixture_id
      ft_goals {
        home
        away
      }
      fh_goals {
        home
        away
      }
      live_goals {
        home
        away
      }
      host_team_data {
        id
        name_en
        name_mm
        code
        cc
      }
      guest_team_data {
        id
        name_en
        name_mm
        code
        cc
      }
      league_data {
        id
        name_en
        name_mm
        cc
      }
      fixture_timestamp
      fixture_start_time
      is_popular_match
      is_perlay
      is_published



      
      odds {
        first_half {
          odds_team
          ou_mm_odds
          hdp_mm_odds
          is_manual_mm_odds
          is_manual_odd_even
          is_manual_1x2
          is_published_mm_odds
        }
        full_time {
          odds_team
          ou_mm_odds
          hdp_mm_odds
          odd
          even
          one
          x
          two
          is_manual_mm_odds
          is_manual_odd_even
          is_manual_1x2
          is_published_mm_odds
          is_published_odd_even
          is_published_1x2
          is_published_correct_score
        }
      }
      correct_scores {
        full_time {
          header
          name
          odds
          id
        }
      }







    }
  }
`;

  const GET_FIXTURES_DATA_SERVICE = () => {
    return useQuery(query,{
        variables: {
            source: "frontend",
            status: "active",
            isPublished: true,
            pageSize: 400,
            page:1,
            markets: ["mm_odds"],
            matchStages: ["full_time"],
        },
        fetchPolicy: 'network-only'
    });
 };

 const fixturesQuery:any = GET_FIXTURES_DATA_SERVICE();


  const [isLoading,setIsLoading] = useState(true);
  const [fixtures,setFixtures] = useState([]);
  const {refresh} = useSelector((state:any) => state.refresh);
  const navigate = useNavigate();

  useEffect(()=>{
    fixturesQuery?.refetch();
    setIsLoading(true);
  },[refresh]);

  useEffect(() => {
    setIsLoading(fixturesQuery?.loading);
    if (fixturesQuery?.error?.message === "Not authenticated" || fixturesQuery?.error?.message.includes("Invalid token") || fixturesQuery?.error?.message.includes("Unauthorized token")|| fixturesQuery?.error?.message.includes("Not Authorised!")) {
      return LogoutHandle(navigate);
    };
    if (fixturesQuery?.data) {
      if (fixturesQuery.data.matches) {
        setFixtures(fixturesQuery.data.matches);
      } else {
        setFixtures([]);
      }
    }
  }, [fixturesQuery]);

  return (
    <div>
      {
        fixtures?.length > 0 && <h1>Total - {fixtures?.length}</h1>
      }
      {
          createPortal(isLoading && <Loader />, document.getElementById('loading') as HTMLElement)
      }
    </div>
  )
}

export default Body
