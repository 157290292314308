import React, { useEffect, useRef, useState } from 'react'
import Matchs from '../Matchs/Matchs'
import { getMethodService } from '../../../services';
import { LogoutHandle, capitalizedText, touchSong } from '../../../utils/Helper';
import { useNavigate } from 'react-router-dom';
import {io} from "socket.io-client";
import { createPortal } from 'react-dom';
import Loader from '../../../utils/Loader/Loader';
import { useDispatch, useSelector } from 'react-redux';
import { leaguesHandler } from '../../../store/slices/leaguesSlice';
import NotFound from '../../../utils/NotFound';
import { GET_FIXTURES_DATA_SERVICE } from '../../../services/graphql/queryService';
import { GET_FIXTURES_DATA } from '../../../services/graphql/queries';
import { message } from 'antd';
import { setBetLists } from '../../../store/slices/betListsSlice';
import { langChange } from '../../../lang';
// import { callStatusHandler } from '../../../store/slices/callMatchsApiSlice';

const Body = () => {
  const fixturesQuery:any = GET_FIXTURES_DATA_SERVICE({isParlay: false});
  const [isLoading,setIsLoading] = useState(true);
  const [fixtures,setFixtures] = useState([]);
  const [sortedFixtures,setSortedFixtures] = useState([]);
  const [leagueNames,setLeagueNames] = useState<any>([]);
  const [totalMatch,setTotalMatch] = useState("-");
  const [newOddsFt,setNewOddsFt] = useState<any>({});
  const [newOddsFh,setNewOddsFh] = useState<any>({});
  const [newOddsCs,setNewOddsCs] = useState<any>({});
  const [searchValue,setSearchValue] = useState([]);
  const [filterByLeagues,setFilterByLeagues] = useState<any>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {filter} = useSelector((state:any) => state.filterSetting);
  const {refresh} = useSelector((state:any) => state.refresh);
  const {search} = useSelector((state:any) => state.marketOdds);
  const [selectActive,setSelectActive] = useState(null);
  const {sortByType} = useSelector((state:any) => state.sortType);

  // GET_FIXTURES_DATA_SERVICE(GET_FIXTURES_DATA)?.then((fixturesQuery:any) => {
  //     setIsLoading(fixturesQuery?.loading);
  //     setFixtures(fixturesQuery?.matches);
  // })
  // .catch((error:any) => console.error(error));

  // useEffect(()=>{
  //   setIsLoading(true);
  // },[refresh]);

  useEffect(()=>{
    dispatch(setBetLists(null));
    // dispatch(callStatusHandler(false));
  },[]);

  // useEffect(()=>{
  //     console.log(fixturesQuery)
  // },[fixturesQuery]);

  useEffect(()=>{
    fixturesQuery?.refetch();
    // setIsLoading(true);
    // setTimeout(()=> setIsLoading(false),1000);
  },[refresh]);

  useEffect(() => {
    setIsLoading(fixturesQuery?.loading);
    if (fixturesQuery?.error?.message === "Not authenticated" || fixturesQuery?.error?.message.includes("Invalid token") || fixturesQuery?.error?.message.includes("Unauthorized token")|| fixturesQuery?.error?.message.includes("Not Authorised!")) {
      return LogoutHandle(navigate);
    };
    if (fixturesQuery?.data) {
      if (fixturesQuery.data.matches) {
        setFixtures(fixturesQuery.data.matches);
      } else {
        setFixtures([]);
      }
    }
  }, [fixturesQuery]);

  useEffect(()=>{
    let url:any= process.env.REACT_APP_SOCCER_DOMAIN;
    const socket = io(url,{
        transports: ["websocket"],
        path: '/api/soccer/socket',
    });
    socket.connect();
    socket.on('connect', () => {
        console.log("Soc Suc!");
        // setOddsStatus(true);
    });
    socket.on('disconnect', () =>  {
        console.log("Soc Dec!");
        // setOddsStatus(false);
    });
    socket.on("ft_odds_updated_data", res => {
      console.log("f_t", res);
      setNewOddsFt(res);
    });
    socket.on("fh_odds_updated_data", res => {
      console.log("f_h", res);
      setNewOddsFh(res);
    });
    socket.on("cs_odds_updated_data", res => {
      // {
      //   match_id: '282',
      //   data: {
      //     id: 4849n,
      //     football_fixture_id: 282n,
      //     fixture_id: '7752120',
      //     name: '1-1',
      //     odds: 8.5,
      //     header: 'Draw',
      //     is_published: true,
      //     match_stage: 'full_time',
      //     created_at: 2024-07-06T05:22:54.000Z,
      //     updated_at: 2024-07-06T08:40:38.000Z
      //   }
      // }
      // console.log("s_c",res)
      setNewOddsCs(res);
    });
    socket.on("hello", res => {
      console.log("hello received", res);
        // setNewOdds(res);
    });
    return () => {
        socket.disconnect();
    };
  },[]);

  useEffect(()=>{
    let bigMatchsArr:any = [];
    let smallMatchsArr:any = [];
    let bigLeagueNamesArr:any = [];
    let smallLeagueNamesArr:any = [];
    let allLeaguesNamesArr:any = [];

    if(search){
        if(searchValue?.length > 0 && typeof searchValue !== "string"){
          let updateOddsFixtures:any = searchValue?.map((fixture:any) => {
              if (fixture?.is_popular_match) {
                bigMatchsArr.push(fixture);
              } else {
                smallMatchsArr.push(fixture);
              };
              // if (fixture?.id == newOddsCs?.match_id) {
              //     if(newOddsCs.data?.match_stage === "full_time"){
              //         fixture?.correct_scores?.full_time?.map((list:any) => {
              //           if(list?.name == newOddsCs?.data?.name_en){
              //               list.odds = newOddsCs?.data?.odds;
              //           };
              //         });
              //     };
              // };
              if (fixture?.id == newOddsFt?.match_id) {
                fixture = {
                  ...fixture,
                  odds: {
                    ...fixture.odds,
                    full_time: {
                      ...fixture.odds.full_time,
                      hdp_mm_odds: newOddsFt?.data?.hdp_mm_odds || fixture?.odds.full_time?.hdp_mm_odds,
                      ou_mm_odds: newOddsFt?.data?.ou_mm_odds || fixture?.odds.full_time?.ou_mm_odds,
                      odd: newOddsFt?.data?.odd || fixture?.odds?.full_time?.odd,
                      even: newOddsFt?.data?.even || fixture?.odds?.full_time?.even,
                      one: newOddsFt?.data?.one || fixture?.odds?.full_time?.one,
                      x: newOddsFt?.data?.x || fixture?.odds?.full_time?.x,
                      two: newOddsFt?.data?.two || fixture?.odds?.full_time?.two,
                    },
                  },
                };
              };
              if (fixture?.id == newOddsFh?.match_id) {
                fixture = {
                  ...fixture,
                  odds: {
                    ...fixture.odds,
                    first_half: {
                      ...fixture.odds.first_half,
                      hdp_mm_odds: newOddsFh?.data?.hdp_mm_odds || fixture?.odds?.first_half?.hdp_mm_odds,
                      ou_mm_odds: newOddsFh?.data?.ou_mm_odds || fixture?.odds?.first_half?.ou_mm_odds,
                    },
                  },
                };
              };
              return fixture;
            });
    
            if (JSON.stringify(updateOddsFixtures) !== JSON.stringify(searchValue)) {
              setSearchValue(updateOddsFixtures);
            };
        };
    }else{
      if (fixtures?.length > 0 && typeof fixtures !== "string") {
        let updateOddsFixtures:any = fixtures.map((fixture: any) => {
          // if (fixture?.id == newOddsCs?.match_id) {
          //     if(newOddsCs.data?.match_stage === "full_time"){
          //         fixture?.correct_scores?.full_time?.map((list:any) => {
          //           if(list?.name == newOddsCs?.data?.name_en){
          //               list.odds = newOddsCs?.data?.odds;
          //           };
          //         });
          //     };
          // };
          if (fixture?.id == newOddsFt?.match_id) {
            fixture = {
              ...fixture,
              odds: {
                ...fixture.odds,
                full_time: {
                  ...fixture.odds.full_time,
                  hdp_mm_odds: newOddsFt?.data?.hdp_mm_odds || fixture?.odds.full_time?.hdp_mm_odds,
                  ou_mm_odds: newOddsFt?.data?.ou_mm_odds || fixture?.odds.full_time?.ou_mm_odds,
                  odd: newOddsFt?.data?.odd || fixture?.odds?.full_time?.odd,
                  even: newOddsFt?.data?.even || fixture?.odds?.full_time?.even,
                  one: newOddsFt?.data?.one || fixture?.odds?.full_time?.one,
                  x: newOddsFt?.data?.x || fixture?.odds?.full_time?.x,
                  two: newOddsFt?.data?.two || fixture?.odds?.full_time?.two,
                },
              },
            };
          };
          if (fixture?.id == newOddsFh?.match_id) {
            fixture = {
              ...fixture,
              odds: {
                ...fixture.odds,
                first_half: {
                  ...fixture.odds.first_half,
                  hdp_mm_odds: newOddsFh?.data?.hdp_mm_odds || fixture?.odds?.first_half?.hdp_mm_odds,
                  ou_mm_odds: newOddsFh?.data?.ou_mm_odds || fixture?.odds?.first_half?.ou_mm_odds,
                },
              },
            };
          };

          if (fixture?.is_popular_match) {
            bigMatchsArr.push(fixture);
          } else {
            smallMatchsArr.push(fixture);
          };
          return fixture;
        });

        if (JSON.stringify(updateOddsFixtures) !== JSON.stringify(fixtures)) {
          setFixtures(updateOddsFixtures);
        };
        
      };      
    };

    // sorting with timeStemp
    if(bigMatchsArr.length > 0){
        bigMatchsArr.map((match:any) => {
          bigLeagueNamesArr.push(match?.league_data?.name_en);
          allLeaguesNamesArr.push(match?.league_data?.name_en);
        });
    };
    if(smallMatchsArr.length > 0){
        smallMatchsArr.map((match:any) => {
          smallLeagueNamesArr.push(match?.league_data?.name_en);
          allLeaguesNamesArr.push(match?.league_data?.name_en);
        });
    };

    let bigLeagueName = new Set(bigLeagueNamesArr);
    let uniqueBigLeagueName = Array.from(bigLeagueName);

    let smallLeagueName = new Set(smallLeagueNamesArr);
    let uniqueSmallLeagueName = Array.from(smallLeagueName);

    let allLeagueName = new Set(allLeaguesNamesArr);
    let uniqueAllLeagueName = Array.from(allLeagueName);

    uniqueAllLeagueName.sort((a:any, b:any) => {
        const leagueNameA = a.toLowerCase();
        const leagueNameB = b.toLowerCase();
        if (leagueNameA < leagueNameB) {
          return -1;
        }
        if (leagueNameA > leagueNameB) {
          return 1;
        }
        return 0;
    });
    setLeagueNames(uniqueAllLeagueName);

    const createLeagueObject = (leagues:any) => {
        return leagues.map((league:any) => {return {league_name: league, timestamp: '', matchs : []}})
    };

    let bigLeagueObj = createLeagueObject(uniqueBigLeagueName);
    let smallLeagueObj = createLeagueObject(uniqueSmallLeagueName);

    const processMatches = (matchesArr:any, leagueObj:any) => {
      if (matchesArr.length > 0) {
          matchesArr.forEach((match:any) => {
              const leagueName = match.league_data?.name_en;
              let findSameLeagueName = leagueObj.find((lea:any) => lea.league_name === leagueName);
              if (findSameLeagueName) {
                  if (findSameLeagueName.timestamp === match.fixture_timestamp) {
                      findSameLeagueName.matchs.push(match);
                  } else {
                      findSameLeagueName.timestamp = match.fixture_timestamp;
                      findSameLeagueName.matchs.push(match);
                  }
              }
          });
      }
    };
    processMatches(bigMatchsArr, bigLeagueObj);
    processMatches(smallMatchsArr, smallLeagueObj);

    // ----------------- Same League but different Fixture start Time -----------------
      let smallSameLeagueDiffTimeArr:any = [];
      let bigSameLeagueDiffTimeArr:any = [];

      if(bigLeagueObj.length > 0){
        bigLeagueObj.map((match:any,i:number) => {
           if(match?.matchs?.length > 1){
            const allSameTime = match?.matchs.every((value:any, index:number, array:any) => value?.fixture_timestamp === array[0]?.fixture_timestamp);
            if(allSameTime){
              bigSameLeagueDiffTimeArr.push(match);
            }else{
                const groupedMatches = match.matchs.reduce((result:any, match:any) => {
                  const index = result.findIndex((group:any) => group.timestamp === match.fixture_timestamp);
                  if (index !== -1) {
                    result[index].matchs.push(match);
                  } else {
                    result.push({
                      league_name: match?.league_data?.name_en,
                      matchs: [match],
                      timestamp: match.fixture_timestamp
                    });
                  }
                  // console.log(result)
                  return result;
                }, []);
                bigSameLeagueDiffTimeArr.push(...groupedMatches);
            };
           }else{
            bigSameLeagueDiffTimeArr.push(match);
           }
        })
      };

      if(smallLeagueObj.length > 0){
        smallLeagueObj.map((match:any,i:number) => {
           if(match?.matchs?.length > 1){
            const allSameTime = match?.matchs.every((value:any, index:number, array:any) => value?.fixture_timestamp === array[0]?.fixture_timestamp);
            if(allSameTime){
              smallSameLeagueDiffTimeArr.push(match);
            }else{
                const groupedMatches = match.matchs.reduce((result:any, match:any) => {
                  const index = result.findIndex((group:any) => group.timestamp === match.fixture_timestamp);
                  if (index !== -1) {
                    result[index].matchs.push(match);
                  } else {
                    result.push({
                      league_name: match?.league_data?.name_en,
                      matchs: [match],
                      timestamp: match.fixture_timestamp
                    });
                  }
                  return result;
                }, []);
                smallSameLeagueDiffTimeArr.push(...groupedMatches);
                // console.log(smallLeagueObj)
            }
           }else{
            smallSameLeagueDiffTimeArr.push(match);
           }
        })
      };
      let bigMatchTotal = bigSameLeagueDiffTimeArr?.reduce((a:any,b:any) => a + b?.matchs?.length, 0)
      // console.log("total big -",bigMatchTotal)

      let smallMatchTotal = smallSameLeagueDiffTimeArr?.reduce((a:any,b:any) => a + b?.matchs?.length, 0)
      // console.log("total small",smallMatchTotal)

      if(sortByType === 'time'){
        let filteredArr1:any = [];
        let filteredArr2:any = [];

        filterByLeagues.forEach((league:any) => {
          const filteredValues = bigSameLeagueDiffTimeArr.filter((obj:any) => obj.league_name === league);
          filteredArr1.push(...filteredValues);
        });
        filterByLeagues.forEach((league:any) => {
          const filteredValues = smallSameLeagueDiffTimeArr.filter((obj:any) => obj.league_name === league);
          filteredArr2.push(...filteredValues);
        });
        filteredArr1.sort((a:any,b:any) => Number(a?.timestamp) - Number(b?.timestamp));
        filteredArr2.sort((a:any,b:any) => Number(a?.timestamp) - Number(b?.timestamp));
        let sortdTime:any = [...filteredArr1,...filteredArr2];
        setSortedFixtures(sortdTime);
      }else{
        let filteredArr1:any = [];
        let filteredArr2:any = [];

        filterByLeagues.forEach((league:any) => {
          const filteredValues = bigSameLeagueDiffTimeArr.filter((obj:any) => obj.league_name === league);
          filteredArr1.push(...filteredValues);
        });
        filterByLeagues.forEach((league:any) => {
          const filteredValues = smallSameLeagueDiffTimeArr.filter((obj:any) => obj.league_name === league);
          filteredArr2.push(...filteredValues);
        });

        let finalSortArr:any = [...filteredArr1,...filteredArr2];
        finalSortArr.sort((a:any, b:any) => {
          const leagueNameA = a.league_name.toLowerCase();
          const leagueNameB = b.league_name.toLowerCase();
          if (leagueNameA < leagueNameB) {
            return -1;
          }
          if (leagueNameA > leagueNameB) {
            return 1;
          }
          return 0;
        });
        setSortedFixtures(finalSortArr);
      };
  },[searchValue,fixtures,newOddsFt,newOddsFh,sortByType,filterByLeagues]);

  useEffect(()=>{
    if(filter?.checkAll){
      if(filter?.leagues?.length === 0){
        dispatch(leaguesHandler(leagueNames));
      };
    };

    if(filter?.leagues?.length > 0){
      setFilterByLeagues(filter?.leagues);
    }else{
        if(!filter?.checkAll){
          setFilterByLeagues(filter?.leagues);
        };
    };
  },[leagueNames,filter]);

  useEffect(()=>{
      const capitalized = capitalizedText(search);
      let find = fixtures?.filter((result:any) => 
        result?.league_data?.name_en?.includes(capitalized) ||
        result?.host_team_data?.name_en?.includes(capitalized) ||
        result?.host_team_data?.name_mm?.includes(capitalized) ||
        result?.guest_team_data?.name_en?.includes(capitalized) ||
        result?.guest_team_data?.name_mm?.includes(capitalized)
      );
      setSearchValue(find);
  },[search]);

  const pressHandler = (activeType:any,fixtureList:any,type:any,market:any,is_first_half:any = false,correctScores_odds:any) => {
    setSelectActive(activeType);
    touchSong();
    if(selectActive === activeType) {
        touchSong();
        dispatch(setBetLists(null));
        return setSelectActive(null);
    };
    let cs_odds = correctScores_odds? {cs_odds: correctScores_odds}: {};
    let data = {
        fixture: fixtureList,
        match_id: fixtureList?.id,
        market,
        team: type,
        match_stage: is_first_half ? 'first_half': 'full_time',
        ...cs_odds
    };
    dispatch(setBetLists([data]));
  };

  // console.log(isLoading)
  
  return (
    <>
      <div style={{height: '100%'}}>
        {
          sortedFixtures?.length > 0 ?
          <Matchs data={sortedFixtures} selectActive={selectActive} pressHandler={pressHandler} changeFtOdds={newOddsFt} changeFhOdds={newOddsFh} />: //changeFtOdds={null} changeFhOdds={null}
          !isLoading && <NotFound text={langChange.notfound} />
          // sortedFixtures?.length > 0 && <h1>Hello {fixtures?.length}</h1>
        }
      </div>
      {
          createPortal((isLoading && fixtures?.length === 0) && <Loader />, document.getElementById('loading') as HTMLElement)
      }
    </>
  )
}

export default Body
