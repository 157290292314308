const slot_domain = process.env.REACT_APP_SLOT_DOMAIN;

let main_domain = process.env.REACT_APP_MAIN_DOMAIN;
let soccer_domain = process.env.REACT_APP_SOCCER_DOMAIN;
let additional_domain = process.env.REACT_APP_ADDITIONAL_DOMAIN;

export const PROVIDER_API = `${slot_domain}api/providers`;
export const GAMES_LIST_API = `${slot_domain}api/game-lists`;
export const PROVIDER_CONNECT_API = `${slot_domain}api/provider-connect-lists`;

export const LOGIN_API = `${main_domain}api/main/auth/login/user`;
export const LOGOUT_API = `${main_domain}api/main/auth/logout`;
export const CHANGE_PASSWORD_API = `${main_domain}api/main/users/change-password`;
export const FOOTBALL_BET_API = `${soccer_domain}api/soccer/bettings`;
export const BANNER_API = `${main_domain}api/main/config/banners`;
export const TOTAL_MATCHES_API = `${soccer_domain}api/soccer/fixtures/upcomming-total-matches`

// SETTING API
export const SETTING_JSON = `https://3xscores.s3.ap-southeast-1.amazonaws.com/control.json`;

export const SETTING_API = `${main_domain}api/main/config`;

export const TEAM_IMAGE = (data) => {
  const encodedString = data?.code;
  // const encodedString = encodeURIComponent(data?.code);
  return `${soccer_domain}api/soccer/fixtures/images/${encodedString}/teams/${data?.name_en}`;
};
