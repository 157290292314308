import React, { useState, useEffect } from 'react';
import classes from './NoInternet.module.css';
const no_internet_img = require('../../assets/images/icons/offline.png');

const NoInternet = ({children}:any) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  if(isOnline){
    return children;
  }else{
    return <div className={classes.container}>
             <div className={classes.imageContainer}>
                  <img src={no_internet_img} />
                  <div>
                    <p>You are offline!</p>
                    <p>Please check internet connection.</p>
                  </div>
             </div>
            
          </div>
  }
};

export default NoInternet;
