import React, { useState } from "react";
import classes from "./WalletTransaction.module.css";
import { FiFilter } from "react-icons/fi";
import DrawerSlide from "../../utils/DrawerSlide";
import { touchSong } from "../../utils/Helper";
import { DatePicker } from "antd";
import ReactDOMServer from "react-dom/server";
import { IoCalendarOutline } from "react-icons/io5";
import { color } from "framer-motion";
import moment from "moment";
import { getTransactionHistory } from "../../services/userService";
import { createPortal } from "react-dom";
import Loader from "../../utils/Loader/Loader";
import NotFound from "../../utils/NotFound";
import { langChange } from "../../lang";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
const depositIcon = require("../../assets/images/icons/deposit.png");
const withdrawlIcon = require("../../assets/images/icons/withdrawal.png");
const betIcon = require("../../assets/images/icons/bet.png");
const rewardIcon = require("../../assets/images/icons/reward.png");
const rejectedIcon = require("../../assets/images/icons/rejected.png");

const getIconDataUrl = (IconComponent: any) => {
  const svgString = ReactDOMServer.renderToStaticMarkup(
    <IconComponent color="#FFF" />
  );
  return `data:image/svg+xml;base64,${btoa(svgString)}`;
};

const WalletTransaction = () => {
  const [openTransFilter, setOpenTransFilter] = useState(false);
  const [filterMainType, setFilterMainType] = useState("all");
  const [filterSubType, setFilterSubType] = useState("all");
  const calendarIconDataUrl = getIconDataUrl(IoCalendarOutline);
  const navigate = useNavigate();

  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD HH:mm:ss")
  );
  const [endDate, setEndDate] = useState(
    moment().format("YYYY-MM-DD HH:mm:ss")
  );
  const [type, setType] = useState<any>(null);
  const [transactionType, setTransactionType] = useState<any>(null);
  const {value} = useSelector((state:any) => state.lang);

  const {
    loading: walletTransactionLoading,
    error: walletTransactionError,
    data: walletTransactionData,
    refetch: walletTransactionRefetch,
    startPolling: walletTransactionStartPolling,
  }:any = getTransactionHistory({
    payload: {
      page: page,
      pageSize: pageSize,
      startDate: startDate,
      endDate: endDate,
      transactionType: transactionType,
      type: type,
    },
    pollInterval: 0,
    isLazy: false,
  });

  const pickerStyle = {
    background: `url(${calendarIconDataUrl}) center no-repeat`,
    width: 16,
    backgroundSize: "contain",
    border: "transparent",
    cursor: "pointer",
  };

  const showFilterTypes = (fixture: any) => {
    setOpenTransFilter(true);
    touchSong();
  };

  const hideFilterTypes = () => {
    setOpenTransFilter(false);
    touchSong();
  };

  const filterMainTypeHandler = (name: any) => {
    console.log(name)
    setFilterMainType(name);
    setFilterSubType("all");
    if(name === "all"){
      setTransactionType(null);
    };
    let value = name == "all" ? null : name == "in" ? "increase" : "decrease";
    setType(value);
    touchSong();
  };

  const filterSubTypeHandler = (name: any) => {
    setFilterSubType(name);
    let value = name == "all" ? null : name;
    setTransactionType(value);
    touchSong();
  };

  const dateHandler = (value: any, dateString: any) => {
    let selected_date = dateString;
    setStartDate(moment(selected_date).startOf("day").format("YYYY-MM-DD HH:mm:ss"));
    setEndDate(moment(selected_date).endOf("day").format("YYYY-MM-DD HH:mm:ss"));
  };

  const _showSlipId = (betId:any,type:any) => {
    let mustTypes = ['betting', 'reward', 'reject', 'rollback'];
    let findType = mustTypes.some(t => t === type);
    if(findType){
        if(betId){
          return betId;
        }else{
          return '-'
        };
    };
  };

  return (
    <>
      <div style={{ height: "100%", position: "relative", paddingTop: "38px" }}>
        <div className={classes.filterContainer}>
          <ul className={classes.filterTypesGroup}>
            <li
              className={filterMainType === "all" ? classes.mainTypeActive : ""}
              onClick={() => filterMainTypeHandler("all")}
              style={{fontSize: value === 'eng'? '15px':'13px'}}
            >
              {langChange.all}
            </li>
            <li
              className={filterMainType === "in" ? classes.mainTypeActive : ""}
              onClick={() => filterMainTypeHandler("in")}
              style={{fontSize: value === 'eng'? '15px':'13px'}}
            >
              {langChange.inflow}
            </li>
            <li
              className={filterMainType === "out" ? classes.mainTypeActive : ""}
              onClick={() => filterMainTypeHandler("out")}
              style={{fontSize: value === 'eng'? '15px':'13px'}}
            >
              {langChange.outflow}
            </li>
          </ul>
          <div
            style={{
              display: "flex",
            }}
          >
            <div
              style={{ marginRight: "7px" }}
              className={classes.moreBtnContainer}
            >
              <button
                className={classes.moreFilterBtn}
                onClick={showFilterTypes}
              >
                <FiFilter size="16px" color="#FFF" />
              </button>
            </div>
            <div className={classes.moreBtnContainer}>
              <button className={classes.moreFilterBtn} onClick={touchSong}>
                <div
                  style={pickerStyle}
                  className="wallet-transaction-date-picker-container"
                >
                  <DatePicker
                    suffixIcon={null}
                    placement="topRight"
                    onChange={dateHandler}
                  />
                </div>
              </button>
            </div>
          </div>
        </div>
        <div className={classes.cardsContainer}>
          {/* {walletTransactionLoading && <h1>Loading...</h1>} */}
          {
            walletTransactionData?.walletTransactions?.data?.length > 0 ?
            walletTransactionData?.walletTransactions?.data?.map((item: any) => (
              <div className={classes.historyCard} onClick={() => navigate(`/wallet-transaction/detail/${item?.id}`)}>
                <div key={item?.id} style={{ display: "flex", alignItems: "center" }}>
                  <img
                    className={classes.typeIconImg}
                    src={_showImage(item?.transaction_type)}
                  />
                  <div>
                    <div style={{lineHeight: '20px',marginBottom: '3px'}}>
                      {/* <span style={{marginRight: '5px'}}>{_showTransactionType(item?.transaction_type, value)}</span> -  */}
                      {langChange.slipId} :{" "}
                      {
                        _showSlipId(item?.remark,item?.transaction_type)
                      }
                      {/* {item?.id} */}
                    </div>
                    <div className={classes.historyDate}>
                      {moment(item?.created_at).format("DD/MM hh:mm:ss A")}
                    </div>
                  </div>
                </div>
                {item?.type == "increase" && (
                  <div style={{ color: "#198754" }} className={classes.amount}>
                    +{item?.amount}
                  </div>
                )}
                {item?.type == "decrease" && (
                  <div style={{ color: "#dc3545" }} className={classes.amount}>
                    -{item?.amount}
                  </div>
                )}
              </div>
            )):
            !walletTransactionLoading && <NotFound text={langChange.notfound} />
          }
        </div>
      </div>
      {
          createPortal(walletTransactionLoading && <Loader />, document.getElementById('loading') as HTMLElement)
      }
      <DrawerSlide
        title="Transaction Types"
        onClose={hideFilterTypes}
        open={openTransFilter}
        placement="bottom"
        height={250}
        showCloseBtn={false}
      >
        <div className={classes.typesListContainer}>
          <div
            className={classes.typeList}
            onClick={() => filterSubTypeHandler("all")}
          >
            <div
              className={`${classes.type} ${
                filterSubType === "all"
                  ? classes.subTypeActive
                  : classes.subTypeInActive
              }`}
              style={{padding: value === 'eng'?'12px 5px' :'5px'}}
            >
              {langChange.all}
            </div>
          </div>
          {(filterMainType === "all" || filterMainType === "in") && (
            <>
              <div
                className={classes.typeList}
                onClick={() => filterSubTypeHandler("deposit")}
              >
                <div
                  className={`${classes.type} ${
                    filterSubType === "deposit"
                      ? classes.subTypeActive
                      : classes.subTypeInActive
                  }`}
                  style={{padding: value === 'eng'?'12px 5px' :'5px'}}
                >
                  {langChange.deposit}
                </div>
              </div>
              <div
                className={classes.typeList}
                onClick={() => filterSubTypeHandler("reward")}
              >
                <div
                  className={`${classes.type} ${
                    filterSubType === "reward"
                      ? classes.subTypeActive
                      : classes.subTypeInActive
                  }`}
                  style={{padding: value === 'eng'?'12px 5px' :'5px'}}
                >
                  {langChange.reward}
                </div>
              </div>
            </>
          )}
          {(filterMainType === "all" || filterMainType === "out") && (
            <>
              <div
                className={classes.typeList}
                onClick={() => filterSubTypeHandler("withdrawl")}
              >
                <div
                  className={`${classes.type} ${
                    filterSubType === "withdrawl"
                      ? classes.subTypeActive
                      : classes.subTypeInActive
                  }`}
                  style={{padding: value === 'eng'?'12px 5px' :'5px'}}
                >
                  {langChange.withdraw}
                </div>
              </div>
              <div
                className={classes.typeList}
                onClick={() => filterSubTypeHandler("betting")}
              >
                <div
                  className={`${classes.type} ${
                    filterSubType === "bet"
                      ? classes.subTypeActive
                      : classes.subTypeInActive
                  }`}
                  style={{padding: value === 'eng'?'12px 5px' :'5px'}}
                >
                  {langChange.bet}
                </div>
              </div>
            </>
          )}
        </div>
      </DrawerSlide>
    </>
  );
};

let _showTransactionType = (type: String, lang: String) => {
  switch (type) {
    case "betting-reject":
      return lang === "eng" ? "Rejected" : "ပွဲပျက်";
    case "deposit":
      return lang === "eng" ? "Deposit" : "ငွေသွင်း";
    case "withdrawl":
      return lang === "eng" ? "Withdrawal" : "ငွေထုတ်";
    case "betting":
      return lang === "eng" ? "Bet" : "လောင်းငွေ";
    case "reward":
      return lang === "eng" ? "Reward" : "နိုင်ငွေ";
    default:
      return lang === "eng" ? "All" : "အားလုံး";
  }
};

let _showImage = (type: String) => {
  switch (type) {
    case "betting-reject":
      return rejectedIcon;
    case "deposit":
      return depositIcon;
    case "withdrawl":
      return withdrawlIcon;
    case "betting":
      return betIcon;
    case "reward":
      return rewardIcon;
  }
};

export default WalletTransaction;
