import { createSlice } from '@reduxjs/toolkit'

const initialState:any = {
    isFocus: false,
}

export const removeInputFocusSlice:any = createSlice({
  name: 'focus',
  initialState,
  reducers: {
    focusHandler: (state:any, action: any) => {
        state.isFocus = action.payload
    },
  },
})

export const { focusHandler } = removeInputFocusSlice.actions

export default removeInputFocusSlice.reducer