import React, { useEffect, useRef, useState } from 'react';
import { MdArrowBackIos } from "react-icons/md";
import classes from './FootballHeader.module.css';
import { BiRefresh } from 'react-icons/bi';
import { PiSortDescendingBold } from "react-icons/pi";
import { IoArrowBackOutline, IoTimerOutline } from "react-icons/io5";
import { useNavigate } from 'react-router-dom';
import { touchSong } from '../../utils/Helper';
import Search from 'antd/es/transfer/search';
import { marketStatusHandler, searchFilterHandler } from '../../store/slices/marketOddsSlice';
import { useDispatch, useSelector } from 'react-redux';
import PageTransition from '../PageTransition';
import { AiOutlineFileSearch } from "react-icons/ai";
import { FiFilter } from "react-icons/fi";
import { TbClockUp } from "react-icons/tb";
// import { refreshHandler } from '../../store/slices/refreshSlice';

const FootballHeader = ({title,showLeagueDrawer,showSortDrawer,onRefresh,showSortByModal}:any) => {
  const [isOpen,setIsOpen] = useState<boolean>(false);
  const [rotateEff,setRotateEff] = useState(false);
  const [searchBox,setSearchBox] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {sortByType} = useSelector((state:any) => state.sortType);

  const backHandler = () => {
   navigate('/');
   touchSong();
  };

  const refreshMatchHandler = (e:any) => {
    setRotateEff(true);
    touchSong();
    onRefresh();
    // setTimeout(()=>{
    //   dispatch(refreshHandler(false));
    // },10)
    // window.location.reload();
    setTimeout(()=>{
      setRotateEff(false);
    },2000);
  };

  const searchHandler = (e:any) => {
    dispatch(searchFilterHandler(e.target.value));
  };

  const backAllMatchsHandler = () => {
    dispatch(searchFilterHandler(""));
    setSearchBox(false)
  };

  const searchBoxToggle = () => {
    setSearchBox(true);
  };

  useEffect(()=>{
    const inputDom:any = document.querySelector('.searchGroup .ant-input');
    if(inputDom){
      inputDom.focus();
    };
  },[searchBox]);
  

  return (
    <>
      <header className={classes.header}>
        {
          searchBox ?
          <PageTransition>
            <div className='searchGroup' style={{height: '100%', display: 'flex', alignItems: 'center'}}>
                <button className={classes.backNormal} onClick={backAllMatchsHandler} >
                  <IoArrowBackOutline size="25px"/>
                </button>
                <Search placeholder="Search..." onChange={searchHandler} />
            </div>
          </PageTransition>:
          <PageTransition>
              <div style={{height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
                  <button className={classes.back} onClick={backHandler}>
                      <MdArrowBackIos size="25px"/>
                  </button>
                  <span className={classes.typeName}>{title}</span>
                  <div style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}>
                      <>
                        <button className={`${classes.headerIconBtn} btn`} style={{marginRight: '10px'}} onClick={showSortByModal}>
                            {
                              sortByType === "time"?
                              <TbClockUp size="23px" />:
                              <PiSortDescendingBold size="23px" />
                            }
                        </button>
                      </>
                      <button className={`${classes.headerIconBtn} btn`} style={{marginRight: '10px'}} onClick={showSortDrawer}>
                          <IoTimerOutline size="23px" />
                      </button>
                      <button className={`${classes.headerIconBtn} btn`} style={{marginRight: '10px'}} onClick={showLeagueDrawer}>
                          <FiFilter size="23px" />
                      </button>
                      <button className={`${classes.headerIconBtn} btn`} style={{marginRight: '10px'}} onClick={searchBoxToggle}>
                          <AiOutlineFileSearch size="23px" />
                      </button>
                      <button disabled={rotateEff} className={`${classes.headerIconBtn} btn ${rotateEff ? classes.rotateEff: ''}`} onClick={refreshMatchHandler}> 
                          <BiRefresh size="28px" className={classes.refreshIcons}/>
                      </button>
                      {/* <button disabled={rotateEff} className={`${classes.headerIconBtn} btn ${rotateEff ? classes.rotateEff: ''}`} onClick={refreshMatchHandler}>
                          <FiRotateCw size="23px" />
                      </button> */}
                      
                  </div>  
              </div>
          </PageTransition>
        }
      </header>
    </>
  )
}

export default FootballHeader
