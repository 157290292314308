import React, { useEffect } from 'react';
import {  BrowserRouter as Router } from 'react-router-dom';
import Routers from './routes';
import { useSelector } from 'react-redux';
import { langChange } from './lang';
import NoInternet from './utils/NoInternet';

function App() {
  const {value} = useSelector((state:any) => state.lang);  
  
  useEffect(()=>{
    localStorage.setItem("lang",value);
    langChange.setLanguage(value);
  },[value]);

  return (
    <>
      <NoInternet>
        <Router basename="">
          <Routers />
        </Router>
      </NoInternet>
    </>
  );
}

export default App;
