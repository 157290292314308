import { useLazyQuery, useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import { useApolloClient } from '@apollo/client';
import { callStatusHandler } from '../../store/slices/callMatchsApiSlice';
import { GET_FIXTURES_DATA } from './queries';
const Cookies = require('js-cookie');

export const GET_USER_INFO_SERVICE = (query,id) => {
    return useQuery(query,{
        variables: {
            id: id,
            setActive: true
        },
        fetchPolicy: 'cache-and-network',
    });
}

export const GET_RESULT_DATA_SERVICE = (query, source, date,sorting, pageSize, page) => {
    let isSourceCompleted = (source === "completed" || source === "cancel") ? {} : (source === "first-half-reject"? {source: 'first-half-complete'} :{ source });
    let isStatusObj = {};
    switch(source){
        case "completed":
            isStatusObj = {status: 'completed'};
        break;
        case "cancel":
            isStatusObj = {status: 'cancel'};
        break
        case "first-half-reject":
            isStatusObj = {status: 'cancel'};
        break
        default:
            isStatusObj = {status: 'active'};
    };

    const { loading, error, data, refetch } = useQuery(query, {
      variables: {
        ...isSourceCompleted,
        ...isStatusObj,
        pageSize: pageSize,
        page: page,
        sorting,
        date,
      },
      fetchPolicy: "network-only",
    });
    return { loading, error, data, refetch };
};

export const GET_FAV_MATCHES_SERVICE = (query,ids) => {
    const { loading, error, data, refetch } = useQuery(query, {
        variables: {
            matchesId: ids
        },
        skip: ids?.length === 0,
        fetchPolicy: "network-only",
    });
    return { loading, error, data, refetch };
};


export const GET_FIXTURES_DATA_SERVICE = ({isParlay,fetchPolicy='cache-and-network'}) => {
    const {marketStatus} = useSelector((state) => state.marketOdds);
    let tempMarkets = [];
    let tempMatchStages = [];

    if(marketStatus?.fullTime || marketStatus?.evenOdd || marketStatus?.onextwo || marketStatus?.corretScores){
        if(!tempMatchStages.includes("full_time")){
            tempMatchStages.push("full_time"); 
        };
    }else{
        tempMatchStages = tempMatchStages.filter(market => market !== "full_time");
    };

    if(marketStatus?.firstHalf){
        if(!tempMatchStages.includes("first_half")){
            tempMatchStages.push("first_half"); 
        };
    }else{
        tempMatchStages = tempMatchStages.filter(market => market !== "first_half");
    };

    if(marketStatus?.firstHalf || marketStatus?.fullTime){
        if(!tempMarkets.includes("mm_odds")){
           tempMarkets.push("mm_odds"); 
        };
    }else{
        tempMarkets = tempMarkets.filter(market => market !== "mm_odds");
    };

    if(marketStatus?.evenOdd){
        if(!tempMarkets.includes("odd_even")){
           tempMarkets.push("odd_even"); 
        };
    }else{
        tempMarkets = tempMarkets.filter(market => market !== "odd_even");
    };

    if(marketStatus?.onextwo){
        if(!tempMarkets.includes("1x2")){
           tempMarkets.push("1x2"); 
        };
    }else{
        tempMarkets = tempMarkets.filter(market => market !== "1x2");
    };

    if(marketStatus?.corretScores){
        if(!tempMarkets.includes("correct_scores")){
           tempMarkets.push("correct_scores"); 
        };
    }else{
        tempMarkets = tempMarkets.filter(market => market !== "correct_scores");
    };

    const skip = (tempMarkets.length === 0 && tempMatchStages.length === 0);
    return useQuery(GET_FIXTURES_DATA({market: tempMarkets,match:tempMatchStages}),{
        skip,
        variables: {
            source: "frontend",
            status: "active",
            isPublished: true,
            pageSize: skip ? 0 :400,
            page: 1,
            markets:tempMarkets,
            matchStages: tempMatchStages,
            isPerlay: isParlay
        },
        fetchPolicy: fetchPolicy
    });
};

// export const GET_FIXTURES_DATA_SERVICE = (query) => {
   
//     const client = useApolloClient();
//     const {marketStatus} = useSelector((state) => state.marketOdds);
//     const {refresh} = useSelector((state) => state.refresh);
//     const {callStatus} = useSelector((state) => state.callMatchs);
//     const dispatch = useDispatch();
    
//     let tempMarkets = [];
//     let tempMatchStages = [];

//     if(marketStatus?.fullTime || marketStatus?.evenOdd || marketStatus?.onextwo || marketStatus?.corretScores){
//         if(!tempMatchStages.includes("full_time")){
//             tempMatchStages.push("full_time"); 
//         };
//     }else{
//         tempMatchStages = tempMatchStages.filter(market => market !== "full_time");
//     };

//     if(marketStatus?.firstHalf){
//         if(!tempMatchStages.includes("first_half")){
//             tempMatchStages.push("first_half"); 
//         };
//     }else{
//         tempMatchStages = tempMatchStages.filter(market => market !== "first_half");
//     };

//     if(marketStatus?.firstHalf || marketStatus?.fullTime){
//         if(!tempMarkets.includes("mm_odds")){
//            tempMarkets.push("mm_odds"); 
//         };
//     }else{
//         tempMarkets = tempMarkets.filter(market => market !== "mm_odds");
//     };

//     if(marketStatus?.evenOdd){
//         if(!tempMarkets.includes("odd_even")){
//            tempMarkets.push("odd_even"); 
//         };
//     }else{
//         tempMarkets = tempMarkets.filter(market => market !== "odd_even");
//     };

//     if(marketStatus?.onextwo){
//         if(!tempMarkets.includes("1x2")){
//            tempMarkets.push("1x2"); 
//         };
//     }else{
//         tempMarkets = tempMarkets.filter(market => market !== "1x2");
//     };

//     if(marketStatus?.corretScores){
//         if(!tempMarkets.includes("correct_scores")){
//            tempMarkets.push("correct_scores"); 
//         };
//     }else{
//         tempMarkets = tempMarkets.filter(market => market !== "correct_scores");
//     };

//     const skip = (tempMarkets.length === 0 && tempMatchStages.length === 0);

//     // let pageSize = 100;

//     // return useQuery(query,{
//     //     skip,
//     //     variables: {
//     //         source: "frontend",
//     //         status: "active",
//     //         isPublished: true,
//     //         pageSize: skip ? 0 :pageSize,
//     //         page: page,
//     //         markets:tempMarkets,
//     //         matchStages: tempMatchStages,
//     //     },
//     //     fetchPolicy: 'network-only'
//     // });

//     const fetchPage = async (page,pageSize) => {
//         return client.query({
//             query,
//             skip,
//             variables: {
//                 source: "frontend",
//                 status: "active",
//                 isPublished: true,
//                 pageSize,
//                 page,
//                 markets: tempMarkets,
//                 matchStages: tempMatchStages,
//             },
//             fetchPolicy: 'network-only'
//         });
//     };

//     const arrayToObject = (arr) => {
//         return arr.reduce((result, current) => {
//             result.matches.push(...current.data.matches);
//             return result;
//         }, { loading: false, matches: [] });
//     };
//     const fetchAllPages = async () => {
//         const getTotalMatches = Cookies.get("total_matches");
//         const totalPages = getTotalMatches > 100 ? Math.ceil(getTotalMatches / 100) : 1;
//         const totalPageSize = getTotalMatches > 100 ? 100 : Number(getTotalMatches);
//         try {
//             const pages = Array.from({ length: totalPages }, (_, i) => i + 1);
//             const promises = pages.map(async page => await fetchPage(page,totalPageSize));
//             const responses = await Promise.all(promises);
//             dispatch(callStatusHandler(true));
//             if(responses?.length > 0){
//                 const result = await arrayToObject(responses);
//                 return Promise.resolve(result);
//             };
//         } catch (err) {
//             dispatch(callStatusHandler(true));
//             return Promise.resolve({ loading: false, matches: [] });
//         };
//     };

//     if (!skip && !callStatus) {
//         return fetchAllPages();
//     };

//     if(refresh){
//         return fetchAllPages();  
//     };

// };

export const GET_BET_LISTS_DATA_SERVICE = (query, pageSize , page) => {
    return useQuery(query,{
        variables: {
            pageSize: pageSize,
            page: page,
        },
        fetchPolicy: 'network-only'
    });
}

export const FetchGraphQL = ({query,variables,pollInterval=0, isLazy=false,fetchPolicy='network-only'}) => {
    const fetchHook = isLazy ? useLazyQuery : useQuery;
    const result = fetchHook(query,{
        variables: variables,
        fetchPolicy: fetchPolicy,
        pollInterval: pollInterval,
    });
    return result;
}

export const GET_BET_LIST_DATA_SERVICE = (query,id) => {
    return useQuery(query,{
        variables: {
            id: id,
        },
        fetchPolicy: 'network-only'
    });
};

export const GET_GOALS_DATA_SERVICE = (query,id) => {
    return useQuery(query,{
        variables: {
            bettingId: id,
        },
        fetchPolicy: 'network-only'
    });
}