import React, { useEffect, useRef, useState } from 'react'
import { Collapse } from 'antd';
import classes from './Matchs.module.css';
import { useSelector } from 'react-redux';
import { MdStar } from 'react-icons/md';
import { TEAM_IMAGE } from '../../../services/apis';
import { langChange } from '../../../lang';
import { replaceZeotoQqual } from '../../../utils/Helper';
import moment from 'moment';
const { Panel } = Collapse;

const MatchCard = ({index,data,setSize,windowWidth,pressHandler,selectActive,changeFtOdds,changeFhOdds}:any) => {
  const {marketStatus} = useSelector((state:any) => state.marketOdds);
  const {value} = useSelector((state:any) => state.lang);
  const [openCorrectScores,setOpenCorrectScores] = useState(false);
  const [correctScoresOdds,setCorrectScoresOdds] = useState<any>(null);
  const rowRef:any = useRef();

  useEffect(() => {
    setSize(index, rowRef?.current?.getBoundingClientRect().height);
  }, [setSize, index, windowWidth]);
  return (
    <div ref={rowRef}>
        {/* <Collapse
            key={index}
            size="small"
            ghost
            defaultActiveKey={[index]}
        >
            <Panel
                header={
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <h3 className={classes.leagueName}> 
                            <span style={{color: 'var(--accent-color)'}}>{data[index]?.league_name}</span>
                        </h3>
                        <span style={{
                                display: 'block',
                                width: '18px',
                                height: '18px',
                                fontSize: '12px',
                                borderRadius: '50%',
                                backgroundColor: 'red',
                                marginLeft: '10px',
                                color: '#FFF',
                                textAlign: 'center',
                                lineHeight: '16px'
                            }}>{data[index]?.matchs.length || 0}
                        </span>
                    </div>
                }
                key={index}
            >
                <li>
                    {
                        data[index]?.matchs.length > 0 &&
                        data[index]?.matchs?.map((fixture:any) => {
                            let homeTeamName = (value === "unicode" || value === "zawgyi") ? (fixture?.host_team_data?.name_mm || fixture?.host_team_data?.name_en) : fixture?.host_team_data?.name_en;
                            let awayTeamName = (value === "unicode" || value === "zawgyi") ? (fixture?.guest_team_data?.name_mm || fixture?.guest_team_data?.name_en) : fixture?.guest_team_data?.name_en;
                            return <div key={fixture.id} className={classes.card}>
                            <time className={classes.start_time}>
                                {
                                    fixture?.is_popular_match &&
                                    <MdStar size="20px" style={{marginRight: '3px'}} color='var(--accent-color)'/>
                                }
                                Start Time : {`${new Date(fixture?.fixture_timestamp * 1000).toLocaleString("en-US",{
                                    timeZone: "Asia/Yangon",
                                    day: "2-digit", 
                                    month: "2-digit", 
                                })} - ${new Date(fixture?.fixture_timestamp * 1000).toLocaleString("en-US",{
                                    timeZone: "Asia/Yangon",
                                    hour: "2-digit", 
                                    minute: "2-digit", 
                                    hour12: true
                                })}`}
                            </time>    
                            <div className={classes.team_container}>
                                <div className={classes.teamH}>
                                    {homeTeamName}
                                    <span className={classes.teamLogoBg}>
                                        <img src={TEAM_IMAGE(fixture?.host_team_data)} />
                                    </span>
                                </div>
                                <span style={{backgroundColor: '#000626' , height: '40px', lineHeight: '40px',padding: '0 3px',fontSize: '14px', color: 'var(--accent-color)'}}>
                                    Vs
                                </span>
                                <div className={classes.teamA}>
                                    <span className={classes.teamLogoBg}>
                                        <img src={TEAM_IMAGE(fixture?.guest_team_data)} />
                                    </span>
                                    {awayTeamName}
                                </div>
                            </div>
                            {
                                (marketStatus?.fullTime && fixture?.odds?.full_time?.is_published_mm_odds) &&
                                <div className={classes.betFlex}>
                                    <div className={classes.type}>FT</div>
                                    <div style={{width: '100%'}}>
                                        <div className={classes.hdp_container}>
                                        
                                            <div className={classes.home} style={{backgroundColor: selectActive === `${fixture?.id}_${homeTeamName}_full_time` ? 'var(--accent-color)':''}} onClick={() => {pressHandler(`${fixture?.id}_${homeTeamName}_full_time`,fixture,'home','body');setCorrectScoresOdds(null);}}>
                                                {langChange.football_home}
                                                {
                                                    fixture?.odds?.full_time?.odds_team === 'home'?
                                                    <span className={classes.home_odds} style={{backgroundColor: selectActive === `${fixture?.id}_${homeTeamName}_full_time` ?  (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.hdp_mm_odds) ? 'red' : '#000626') : (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.hdp_mm_odds) ? 'red' : ''), color: selectActive === `${fixture?.id}_${homeTeamName}_full_time` ? '#FFF':(((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.hdp_mm_odds) ? '#FFF' : '')}}>
                                                        {replaceZeotoQqual(fixture?.odds?.full_time?.hdp_mm_odds)}
                                                    </span>:
                                                    ''
                                                }
                                            </div>
                                            <div className={classes.away} style={{backgroundColor: selectActive === `${fixture?.id}_${awayTeamName}_full_time` ? 'var(--accent-color)':''}} onClick={() => {pressHandler(`${fixture?.id}_${awayTeamName}_full_time`,fixture,'away','body');setCorrectScoresOdds(null);}}>
                                                {langChange.football_away}
                                                {
                                                    fixture?.odds?.full_time?.odds_team === 'away'?
                                                    <span className={classes.away_odds} style={{backgroundColor: selectActive === `${fixture?.id}_${awayTeamName}_full_time` ? (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.hdp_mm_odds) ? 'red' : '#000626') : (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.hdp_mm_odds) ? 'red' : ''), color: selectActive === `${fixture?.id}_${awayTeamName}_full_time` ? '#FFF':(((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.hdp_mm_odds) ? '#FFF' : '')}}>
                                                        {replaceZeotoQqual(fixture?.odds?.full_time?.hdp_mm_odds)}
                                                    </span>:
                                                    ''
                                                }
                                            </div>
                                        </div>
                                        <div className={classes.ou_container}>
                                            <div className={classes.over} style={{backgroundColor: selectActive === `${fixture?.id}goalUp_full_time` ? 'var(--accent-color)': ''}} onClick={() => {pressHandler(`${fixture?.id}goalUp_full_time`,fixture,'over','total');setCorrectScoresOdds(null);}}>{langChange.football_over}</div>
                                            <div className={classes.ou_odds} >
                                                <div style={{backgroundColor: (selectActive === `${fixture?.id}goalUp_full_time`) || (selectActive === `${fixture?.id}goalDown_full_time`) ? (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.ou_mm_odds) ? 'red' : '#000626') : (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.ou_mm_odds) ? 'red' : ''),color: (selectActive === `${fixture?.id}goalUp_full_time`) || (selectActive === `${fixture?.id}goalDown_full_time`) ? '#FFF':(((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.ou_mm_odds) ? '#FFF' : '')}}>{replaceZeotoQqual(fixture?.odds?.full_time?.ou_mm_odds)}</div>
                                            </div>
                                            <div className={classes.under} style={{backgroundColor: selectActive === `${fixture?.id}goalDown_full_time` ? 'var(--accent-color)':''}} onClick={() => {pressHandler(`${fixture?.id}goalDown_full_time`,fixture, 'under','total');setCorrectScoresOdds(null);}}>{langChange.football_under}</div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                (marketStatus?.firstHalf && fixture?.odds?.first_half?.is_published_mm_odds) &&
                                <div className={classes.betFlex}>
                                    <div className={classes.type}>HT</div>
                                    <div style={{width: '100%'}}>
                                        <div className={classes.hdp_container}>
                                            <div className={classes.home} style={{backgroundColor: selectActive === `${fixture?.id}_${homeTeamName}_first_half` ? 'var(--accent-color)': ''}} onClick={() => {pressHandler(`${fixture?.id}_${homeTeamName}_first_half`,fixture,'home','body',true);setCorrectScoresOdds(null);}}>
                                                {langChange.football_home}
                                                {
                                                    fixture?.odds?.first_half?.odds_team === 'home'?
                                                    <span className={classes.home_odds} style={{backgroundColor: selectActive === `${fixture?.id}_${homeTeamName}_first_half` ? (((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.hdp_mm_odds) ? 'red' : '#000626') : (((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.hdp_mm_odds) ? 'red' : ''), color: selectActive === `${fixture?.id}_${homeTeamName}_first_half` ? '#FFF':(((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.hdp_mm_odds) ? '#FFF' : '')}}>
                                                        {replaceZeotoQqual(fixture?.odds?.first_half?.hdp_mm_odds)}
                                                    </span>:
                                                    ''
                                                }
                                            </div>
                                            <div className={classes.away} style={{backgroundColor: selectActive === `${fixture?.id}_${awayTeamName}_first_half` ? 'var(--accent-color)': ''}} onClick={() => {pressHandler(`${fixture?.id}_${awayTeamName}_first_half`,fixture,'away','body',true);setCorrectScoresOdds(null);}}>
                                                {langChange.football_away}
                                                {
                                                    fixture?.odds?.first_half?.odds_team === 'away'?
                                                    <span className={classes.away_odds} style={{backgroundColor: selectActive === `${fixture?.id}_${awayTeamName}_first_half` ? (((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.hdp_mm_odds) ? 'red' : '#000626') : (((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.hdp_mm_odds) ? 'red' : ''), color: selectActive === `${fixture?.id}_${awayTeamName}_first_half` ? '#FFF':(((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.hdp_mm_odds) ? '#FFF' : '')}}>
                                                        {replaceZeotoQqual(fixture?.odds?.first_half?.hdp_mm_odds)}
                                                    </span>:
                                                    ''
                                                }
                                            </div>
                                        </div>
                                        <div className={classes.ou_container}>
                                            <div className={classes.over} style={{backgroundColor: selectActive === `${fixture?.id}goalUp_first_half`? 'var(--accent-color)': ''}} onClick={() => {pressHandler(`${fixture?.id}goalUp_first_half`,fixture,'over','total',true);setCorrectScoresOdds(null);}}>{langChange.football_over}</div>
                                            <div className={classes.ou_odds}>
                                                <div style={{backgroundColor: (selectActive === `${fixture?.id}goalUp_first_half`) || (selectActive === `${fixture?.id}goalDown_first_half`) ? (((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.ou_mm_odds) ? 'red' : '#000626') : (((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.ou_mm_odds) ? 'red' : ''),color: (selectActive === `${fixture?.id}goalUp_first_half`) || (selectActive === `${fixture?.id}goalDown_first_half`) ? '#FFF':(((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.ou_mm_odds) ? '#FFF' : '')}}>{replaceZeotoQqual(fixture?.odds?.first_half?.ou_mm_odds)}</div>
                                            </div>
                                            <div className={classes.under} style={{backgroundColor: selectActive === `${fixture?.id}goalDown_first_half`? 'var(--accent-color)': ''}} onClick={() => {pressHandler(`${fixture?.id}goalDown_first_half`,fixture, 'under','total',true);setCorrectScoresOdds(null);}}>{langChange.football_under}</div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                (marketStatus?.evenOdd && fixture?.odds?.full_time?.is_published_odd_even) &&
                                <div className={classes.betFlex}>
                                    <div className={classes.evenOddType}>E/O</div>
                                    <div style={{width: '100%'}}>
                                        <div className={classes.hdp_container}>
                                            <div className={classes.home} onClick={() => {pressHandler(`${fixture?.id}_even`,fixture,'even','odd_even');setCorrectScoresOdds(null);}} style={{borderRadius: '3px 0 0 3px',backgroundColor: selectActive === `${fixture?.id}_even`? 'var(--accent-color)': ''}}>
                                                {langChange.football_even}
                                                <span className={classes.home_odds} style={{backgroundColor: selectActive === `${fixture?.id}_even`? (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.even) ? 'red' : '#000626') : (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.even) ? 'red' : ''), color: selectActive === `${fixture?.id}_even`? '#FFF': (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.even) ? '#FFF' : '')}}>
                                                    {fixture?.odds?.full_time?.even}
                                                </span>
                                            </div>
                                            <div className={classes.away} onClick={() => {pressHandler(`${fixture?.id}_odd`,fixture,'odd','odd_even');setCorrectScoresOdds(null);}} style={{borderRadius: '0 3px 3px 0',backgroundColor: selectActive === `${fixture?.id}_odd`? 'var(--accent-color)': ''}}>
                                                {langChange.football_odd}
                                                <span className={classes.away_odds} style={{backgroundColor: selectActive === `${fixture?.id}_odd`? (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.odd) ? 'red' : '#000626') : (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.odd) ? 'red' : ''), color: selectActive === `${fixture?.id}_odd`? '#FFF': (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.odd) ? '#FFF' : '')}}>
                                                    {fixture?.odds?.full_time?.odd}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {
                                (marketStatus?.onextwo && fixture?.odds?.full_time?.is_published_1x2) &&
                                <div className={classes.betFlex}>
                                    <div className={classes.evenOddType}>1X2</div>
                                    <div style={{width: '100%'}}>
                                        <div className={classes.hdp_container}>
                                            <div className={classes.home} onClick={() => {pressHandler(`${fixture?.id}_one`,fixture,'home','one_x_two');setCorrectScoresOdds(null);}} style={{borderRadius: '3px 0 0 3px',backgroundColor: selectActive === `${fixture?.id}_one`? 'var(--accent-color)': ''}}>
                                                1
                                                <span className={classes.home_odds} style={{backgroundColor: selectActive === `${fixture?.id}_one`? (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.one) ? 'red' : '#000626') : (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.one) ? 'red' : ''), color: selectActive === `${fixture?.id}_one`? '#FFF': (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.one) ? '#FFF' : '')}}>
                                                    {fixture?.odds?.full_time?.one}
                                                </span>
                                            </div>
                                            <div className={classes.home} onClick={() => {pressHandler(`${fixture?.id}_x`,fixture,'draw','one_x_two');setCorrectScoresOdds(null);}} style={{borderRadius: '0',backgroundColor: selectActive === `${fixture?.id}_x`? 'var(--accent-color)': ''}}>
                                                X
                                                <span className={classes.home_odds} style={{backgroundColor: selectActive === `${fixture?.id}_x`? (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.x) ? 'red' : '#000626') : (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.x) ? 'red' : ''), color: selectActive === `${fixture?.id}_x`? '#FFF': (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.x) ? '#FFF' : '')}}>
                                                    {fixture?.odds?.full_time?.x}
                                                </span>
                                            </div>
                                            <div className={classes.away} onClick={() => {pressHandler(`${fixture?.id}_two`,fixture,'away','one_x_two');setCorrectScoresOdds(null);}} style={{borderRadius: '0 3px 3px 0', backgroundColor: selectActive === `${fixture?.id}_two`? 'var(--accent-color)': ''}}>
                                                2
                                                <span className={classes.away_odds} style={{backgroundColor: selectActive === `${fixture?.id}_two`? (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.two) ? 'red' : '#000626') : (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.two) ? 'red' : ''), color: selectActive === `${fixture?.id}_two`? '#FFF': (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.two) ? '#FFF' : '')}}>
                                                    {fixture?.odds?.full_time?.two}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        
                        })
                    }
                </li>
            </Panel>
        </Collapse> */}
        <div style={{display: 'flex', alignItems: 'center'}}>
            <h3 className={classes.leagueName}> 
                <span style={{color: 'var(--accent-color)'}}>{data[index]?.league_name}</span>
            </h3>
            <span style={{
                    display: 'block',
                    width: '18px',
                    height: '18px',
                    fontSize: '12px',
                    borderRadius: '50%',
                    backgroundColor: 'red',
                    marginLeft: '10px',
                    color: '#FFF',
                    textAlign: 'center',
                    lineHeight: '16px'
                }}>{data[index]?.matchs.length || 0}
            </span>
        </div>
        <ul className={classes.matchs}>
            <li>
                {
                    data[index]?.matchs.length > 0 &&
                    data[index]?.matchs?.map((fixture:any) => {
                        let homeTeamName = (value === "unicode" || value === "zawgyi") ? (fixture?.host_team_data?.name_mm || fixture?.host_team_data?.name_en) : fixture?.host_team_data?.name_en;
                        let awayTeamName = (value === "unicode" || value === "zawgyi") ? (fixture?.guest_team_data?.name_mm || fixture?.guest_team_data?.name_en) : fixture?.guest_team_data?.name_en;

                        // let homeTeamName = (fixture?.host_team_data?.name_mm || fixture?.host_team_data?.name_en);
                        // let awayTeamName = (fixture?.guest_team_data?.name_mm || fixture?.guest_team_data?.name_en);

                        return <div key={fixture.id} className={classes.card}>
                        <time className={classes.start_time}>
                            {
                                fixture?.is_popular_match &&
                                <MdStar size="20px" style={{marginRight: '3px'}} color='var(--accent-color)'/>
                            }
                            Start Time : {`${moment(fixture?.fixture_start_time).format('DD/MM')} - 
                            ${moment(fixture?.fixture_start_time).format('hh:mm A')}`}
                        </time>    
                        <div className={classes.team_container}>
                            <div className={classes.teamH}>
                                {homeTeamName}
                                <span className={classes.teamLogoBg}>
                                    <img src={TEAM_IMAGE(fixture?.host_team_data)} />
                                </span>
                            </div>
                            <span style={{backgroundColor: '#000626' , height: '35px', lineHeight: '35px',padding: '0 3px',fontSize: '14px', color: 'var(--accent-color)'}}>
                                Vs
                            </span>
                            <div className={classes.teamA}>
                                <span className={classes.teamLogoBg}>
                                    <img src={TEAM_IMAGE(fixture?.guest_team_data)} />
                                </span>
                                {awayTeamName}
                            </div>
                        </div>
                        {
                            (marketStatus?.fullTime && fixture?.odds?.full_time?.is_published_mm_odds) &&
                            <div className={classes.betFlex}>
                                <div className={classes.type}>FT</div>
                                <div style={{width: '100%'}}>
                                    <div className={classes.hdp_container}>
                                    
                                        <div className={classes.home} style={{backgroundColor: selectActive === `${fixture?.id}_${homeTeamName}_full_time` ? 'var(--accent-color)':''}} onClick={() => {pressHandler(`${fixture?.id}_${homeTeamName}_full_time`,fixture,'home','body');setCorrectScoresOdds(null);}}>
                                            {langChange.football_home}
                                            {
                                                fixture?.odds?.full_time?.odds_team === 'home'?
                                                <span className={classes.home_odds} style={{backgroundColor: selectActive === `${fixture?.id}_${homeTeamName}_full_time` ?  (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.hdp_mm_odds) ? 'red' : '#000626') : (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.hdp_mm_odds) ? 'red' : ''), color: selectActive === `${fixture?.id}_${homeTeamName}_full_time` ? '#FFF':(((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.hdp_mm_odds) ? '#FFF' : '')}}>
                                                    {replaceZeotoQqual(fixture?.odds?.full_time?.hdp_mm_odds)}
                                                </span>:
                                                ''
                                            }
                                        </div>
                                        <div className={classes.away} style={{backgroundColor: selectActive === `${fixture?.id}_${awayTeamName}_full_time` ? 'var(--accent-color)':''}} onClick={() => {pressHandler(`${fixture?.id}_${awayTeamName}_full_time`,fixture,'away','body');setCorrectScoresOdds(null);}}>
                                            {langChange.football_away}
                                            {
                                                fixture?.odds?.full_time?.odds_team === 'away'?
                                                <span className={classes.away_odds} style={{backgroundColor: selectActive === `${fixture?.id}_${awayTeamName}_full_time` ? (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.hdp_mm_odds) ? 'red' : '#000626') : (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.hdp_mm_odds) ? 'red' : ''), color: selectActive === `${fixture?.id}_${awayTeamName}_full_time` ? '#FFF':(((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.hdp_mm_odds) ? '#FFF' : '')}}>
                                                    {replaceZeotoQqual(fixture?.odds?.full_time?.hdp_mm_odds)}
                                                </span>:
                                                ''
                                            }
                                        </div>
                                    </div>
                                    <div className={classes.ou_container}>
                                        <div className={classes.over} style={{backgroundColor: selectActive === `${fixture?.id}goalUp_full_time` ? 'var(--accent-color)': ''}} onClick={() => {pressHandler(`${fixture?.id}goalUp_full_time`,fixture,'over','total');setCorrectScoresOdds(null);}}>{langChange.football_over}</div>
                                        <div className={classes.ou_odds} >
                                            <div style={{backgroundColor: (selectActive === `${fixture?.id}goalUp_full_time`) || (selectActive === `${fixture?.id}goalDown_full_time`) ? (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.ou_mm_odds) ? 'red' : '#000626') : (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.ou_mm_odds) ? 'red' : ''),color: (selectActive === `${fixture?.id}goalUp_full_time`) || (selectActive === `${fixture?.id}goalDown_full_time`) ? '#FFF':(((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.ou_mm_odds) ? '#FFF' : '')}}>{replaceZeotoQqual(fixture?.odds?.full_time?.ou_mm_odds)}</div>
                                        </div>
                                        <div className={classes.under} style={{backgroundColor: selectActive === `${fixture?.id}goalDown_full_time` ? 'var(--accent-color)':''}} onClick={() => {pressHandler(`${fixture?.id}goalDown_full_time`,fixture, 'under','total');setCorrectScoresOdds(null);}}>{langChange.football_under}</div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            (marketStatus?.firstHalf && fixture?.odds?.first_half?.is_published_mm_odds) &&
                            <div className={classes.betFlex}>
                                {/* <div className={classes.type} style={{lineHeight: '55px'}}>1<sup>st</sup>H</div> */}
                                <div className={classes.type}>HT</div>
                                <div style={{width: '100%'}}>
                                    <div className={classes.hdp_container}>
                                        <div className={classes.home} style={{backgroundColor: selectActive === `${fixture?.id}_${homeTeamName}_first_half` ? 'var(--accent-color)': ''}} onClick={() => {pressHandler(`${fixture?.id}_${homeTeamName}_first_half`,fixture,'home','body',true);setCorrectScoresOdds(null);}}>
                                            {langChange.football_home}
                                            {
                                                fixture?.odds?.first_half?.odds_team === 'home'?
                                                <span className={classes.home_odds} style={{backgroundColor: selectActive === `${fixture?.id}_${homeTeamName}_first_half` ? (((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.hdp_mm_odds) ? 'red' : '#000626') : (((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.hdp_mm_odds) ? 'red' : ''), color: selectActive === `${fixture?.id}_${homeTeamName}_first_half` ? '#FFF':(((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.hdp_mm_odds) ? '#FFF' : '')}}>
                                                    {replaceZeotoQqual(fixture?.odds?.first_half?.hdp_mm_odds)}
                                                </span>:
                                                ''
                                            }
                                        </div>
                                        <div className={classes.away} style={{backgroundColor: selectActive === `${fixture?.id}_${awayTeamName}_first_half` ? 'var(--accent-color)': ''}} onClick={() => {pressHandler(`${fixture?.id}_${awayTeamName}_first_half`,fixture,'away','body',true);setCorrectScoresOdds(null);}}>
                                            {langChange.football_away}
                                            {
                                                fixture?.odds?.first_half?.odds_team === 'away'?
                                                <span className={classes.away_odds} style={{backgroundColor: selectActive === `${fixture?.id}_${awayTeamName}_first_half` ? (((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.hdp_mm_odds) ? 'red' : '#000626') : (((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.hdp_mm_odds) ? 'red' : ''), color: selectActive === `${fixture?.id}_${awayTeamName}_first_half` ? '#FFF':(((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.hdp_mm_odds) ? '#FFF' : '')}}>
                                                    {replaceZeotoQqual(fixture?.odds?.first_half?.hdp_mm_odds)}
                                                </span>:
                                                ''
                                            }
                                        </div>
                                    </div>
                                    <div className={classes.ou_container}>
                                        <div className={classes.over} style={{backgroundColor: selectActive === `${fixture?.id}goalUp_first_half`? 'var(--accent-color)': ''}} onClick={() => {pressHandler(`${fixture?.id}goalUp_first_half`,fixture,'over','total',true);setCorrectScoresOdds(null);}}>{langChange.football_over}</div>
                                        <div className={classes.ou_odds}>
                                            <div style={{backgroundColor: (selectActive === `${fixture?.id}goalUp_first_half`) || (selectActive === `${fixture?.id}goalDown_first_half`) ? (((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.ou_mm_odds) ? 'red' : '#000626') : (((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.ou_mm_odds) ? 'red' : ''),color: (selectActive === `${fixture?.id}goalUp_first_half`) || (selectActive === `${fixture?.id}goalDown_first_half`) ? '#FFF':(((changeFhOdds?.match_id == fixture?.id) && changeFhOdds?.data?.ou_mm_odds) ? '#FFF' : '')}}>{replaceZeotoQqual(fixture?.odds?.first_half?.ou_mm_odds)}</div>
                                        </div>
                                        <div className={classes.under} style={{backgroundColor: selectActive === `${fixture?.id}goalDown_first_half`? 'var(--accent-color)': ''}} onClick={() => {pressHandler(`${fixture?.id}goalDown_first_half`,fixture, 'under','total',true);setCorrectScoresOdds(null);}}>{langChange.football_under}</div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            (marketStatus?.evenOdd && fixture?.odds?.full_time?.is_published_odd_even) &&
                            <div className={classes.betFlex}>
                                <div className={classes.evenOddType}>E/O</div>
                                <div style={{width: '100%'}}>
                                    <div className={classes.hdp_container}>
                                        <div className={classes.home} onClick={() => {pressHandler(`${fixture?.id}_even`,fixture,'even','odd_even');setCorrectScoresOdds(null);}} style={{borderRadius: '3px 0 0 3px',backgroundColor: selectActive === `${fixture?.id}_even`? 'var(--accent-color)': ''}}>
                                            {langChange.football_even}
                                            <span className={classes.home_odds} style={{backgroundColor: selectActive === `${fixture?.id}_even`? (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.even) ? 'red' : '#000626') : (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.even) ? 'red' : ''), color: selectActive === `${fixture?.id}_even`? '#FFF': (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.even) ? '#FFF' : '')}}>
                                                {fixture?.odds?.full_time?.even}
                                            </span>
                                        </div>
                                        <div className={classes.away} onClick={() => {pressHandler(`${fixture?.id}_odd`,fixture,'odd','odd_even');setCorrectScoresOdds(null);}} style={{borderRadius: '0 3px 3px 0',backgroundColor: selectActive === `${fixture?.id}_odd`? 'var(--accent-color)': ''}}>
                                            {langChange.football_odd}
                                            <span className={classes.away_odds} style={{backgroundColor: selectActive === `${fixture?.id}_odd`? (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.odd) ? 'red' : '#000626') : (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.odd) ? 'red' : ''), color: selectActive === `${fixture?.id}_odd`? '#FFF': (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.odd) ? '#FFF' : '')}}>
                                                {fixture?.odds?.full_time?.odd}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            (marketStatus?.onextwo && fixture?.odds?.full_time?.is_published_1x2) &&
                            <div className={classes.betFlex}>
                                <div className={classes.evenOddType}>1X2</div>
                                <div style={{width: '100%'}}>
                                    <div className={classes.hdp_container}>
                                        <div className={classes.home} onClick={() => {pressHandler(`${fixture?.id}_one`,fixture,'home','one_x_two');setCorrectScoresOdds(null);}} style={{borderRadius: '3px 0 0 3px',backgroundColor: selectActive === `${fixture?.id}_one`? 'var(--accent-color)': ''}}>
                                            1
                                            <span className={classes.home_odds} style={{backgroundColor: selectActive === `${fixture?.id}_one`? (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.one) ? 'red' : '#000626') : (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.one) ? 'red' : ''), color: selectActive === `${fixture?.id}_one`? '#FFF': (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.one) ? '#FFF' : '')}}>
                                                {fixture?.odds?.full_time?.one}
                                            </span>
                                        </div>
                                        <div className={classes.home} onClick={() => {pressHandler(`${fixture?.id}_x`,fixture,'draw','one_x_two');setCorrectScoresOdds(null);}} style={{borderRadius: '0',backgroundColor: selectActive === `${fixture?.id}_x`? 'var(--accent-color)': ''}}>
                                            X
                                            <span className={classes.home_odds} style={{backgroundColor: selectActive === `${fixture?.id}_x`? (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.x) ? 'red' : '#000626') : (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.x) ? 'red' : ''), color: selectActive === `${fixture?.id}_x`? '#FFF': (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.x) ? '#FFF' : '')}}>
                                                {fixture?.odds?.full_time?.x}
                                            </span>
                                        </div>
                                        <div className={classes.away} onClick={() => {pressHandler(`${fixture?.id}_two`,fixture,'away','one_x_two');setCorrectScoresOdds(null);}} style={{borderRadius: '0 3px 3px 0', backgroundColor: selectActive === `${fixture?.id}_two`? 'var(--accent-color)': ''}}>
                                            2
                                            <span className={classes.away_odds} style={{backgroundColor: selectActive === `${fixture?.id}_two`? (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.two) ? 'red' : '#000626') : (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.two) ? 'red' : ''), color: selectActive === `${fixture?.id}_two`? '#FFF': (((changeFtOdds?.match_id == fixture?.id) && changeFtOdds?.data?.two) ? '#FFF' : '')}}>
                                                {fixture?.odds?.full_time?.two}
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }
                        {/* {
                            (marketStatus?.corretScores && fixture?.odds?.full_time?.is_published_correct_score) &&
                            <div className={classes.betFlex}>
                                <div className={classes.evenOddType}>CS</div>
                                <div style={{width: '100%'}}>
                                    <div className={classes.hdp_container}>
                                        <div className={classes.over} style={{borderRadius: '3px', backgroundColor: selectActive ? ((correctScoresOdds?.id === fixture?.id) ? 'var(--accent-color)':'#616161'): '#616161'}} onClick={() => showCorrectScores(fixture)}>
                                            {selectActive ? (correctScoresOdds?.id === fixture?.id ? correctScoresOdds?.value || langChange.correct_scores : langChange.correct_scores): langChange.correct_scores}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        } */}
                    </div>
                    
                    })
                }
            </li>
        </ul>
        <div style={{height: '7px'}}></div>
    </div>
  )
}

export default MatchCard
