import React, { useEffect } from 'react'
import FootballTypes from '../Football/FootballTypes/FootballTypes'
import Banner from '../Banner/Banner'
import { createPortal } from 'react-dom'
import Loader from '../../utils/Loader/Loader'
import { encodeAuth } from '../../utils/Helper'
import { GET_FIXTURES_DATA_SERVICE } from '../../services/graphql/queryService';

const Home = () => {
    // const fixturesQuery:any = GET_FIXTURES_DATA_SERVICE({isParlay: false});

    // useEffect(() => {
    //   if (fixturesQuery?.data) {
    //     if (fixturesQuery.data.matches) {
    //      console.log(fixturesQuery.data.matches.length)
    //     }
    //   }
    // }, [fixturesQuery]);

  return (
    <>
      <div>
        <Banner />
        {/* <UserInfo mb='10px' /> */}
        <FootballTypes />
      </div>
      {/* {createPortal(
        taxLoading && <Loader />,
        document.getElementById("loading") as HTMLElement
      )} */}
    </>
  )
}

export default Home
